import React from 'react'
import { Box, Row, Badge, Button, Tooltip } from 'src/ui'
import { DetailItem } from 'src/customer/detail/detail-item'
import * as utils from 'src/utils'

export function SubscriptionRow({
  title = 'Active Subscription',
  subscription,
  allowCancel = false,
  isCanceling = false,
  cancelSubscription,
}: {
  title?: string
  subscription: CustomerSubscription
  allowCancel?: boolean
  isCanceling?: boolean
  cancelSubscription?: (args: { subscriptionId: string }) => void
}) {
  const [statusBadgeColor, statusBadgeLabel] = statusInfo(subscription)
  const pricingBreakdown = subscription.pricing?.breakdown

  return (
    <BorderedBox>
      {/* HEADER */}
      <Row mb={3}>
        <Row flex={1} justifyContent="space-between">
          <Box color="gray.600">{title}</Box>

          <Box>
            <Badge colorScheme={statusBadgeColor}>{statusBadgeLabel}</Badge>

            {allowCancel && !subscription.canceled_at && subscription.subscription && (
              <Button
                size="xs"
                colorScheme="red"
                ml={2}
                isLoading={isCanceling}
                onClick={() => cancelSubscription({ subscriptionId: subscription.id })}
              >
                Cancel
              </Button>
            )}
          </Box>
        </Row>
      </Row>

      {/* DATA */}
      <Row>
        <Row flex={1} justifyContent="space-between">
          <DetailItem
            label="Variant"
            value={<strong>{subscription?.pricing?.variant?.name}</strong>}
          />
          <DetailItem label="Started" value={utils.formatLocal(subscription?.created_at, 'L')} />
          {subscription.canceled_at && (
            <DetailItem label="Ended" value={utils.formatLocal(subscription.canceled_at, 'L')} />
          )}
          <DetailItem label="Units" value={`${subscription.pricing?.unit_count} panels`} />

          <DetailItem
            label="Cost"
            value={
              <Tooltip label={`${utils.formatMoney(pricingBreakdown?.per_unit_price)}/panel`}>
                <Box>
                  <>{cleanPrice(pricingBreakdown?.subscription_price_per_year)}/yr</>
                  {/*  */} {/*  */}
                  <>({cleanPrice(pricingBreakdown?.subscription_price_per_month)}/mo)</>
                </Box>
              </Tooltip>
            }
          />
        </Row>
      </Row>
    </BorderedBox>
  )
}

///////////////////////////////////////////////////////////////////////////////////////////////////

export function SubscriptionPreview({
  customer,
  serviceVariant,
  breakdown,
}: {
  customer: Customer
  serviceVariant: TaskServiceVariant
  breakdown: PricingBreakdown
}) {
  if (!breakdown) return null

  return (
    <BorderedBox>
      {/* HEADER */}
      <Row mb={3}>
        <Row flex={1} justifyContent="space-between">
          <Box color="gray.600">Subscription Preview</Box>
        </Row>
      </Row>

      {/* DATA */}
      <Row>
        <Row flex={1} justifyContent="space-between">
          <DetailItem label="Variant" value={<strong>{serviceVariant?.name}</strong>} />
          <DetailItem label="Starts On" value={utils.formatLocal(undefined, 'L')} />

          <DetailItem
            label="Units"
            value={`${customer.meta?.[serviceVariant.unit_meta_key] ?? '?'} panels`}
          />
          <DetailItem
            label="Unit Price"
            value={`${utils.formatMoney(breakdown?.per_unit_price)}`}
          />

          <DetailItem
            label="Pricing"
            value={
              <Tooltip label={`${utils.formatMoney(breakdown?.per_unit_price)}/panel`}>
                <Box>
                  <>{cleanPrice(breakdown?.subscription_price_per_year)}/yr</>
                  {/*  */} {/*  */}
                  <>({cleanPrice(breakdown?.subscription_price_per_month)}/mo)</>
                </Box>
              </Tooltip>
            }
          />
        </Row>
      </Row>
    </BorderedBox>
  )
}

///////////////////////////////////////////////////////////////////////////////////////////////////

function BorderedBox({ children }) {
  return (
    <Box border="1px solid" borderColor="gray.100" borderRadius={4} boxShadow="md" p={3}>
      {children}
    </Box>
  )
}

function cleanPrice(value: number) {
  return utils.formatMoney(value)?.replace('.00', '')
}

function statusInfo(subscription: CustomerSubscription): [string, string] {
  if (subscription.canceled_at) return ['red', 'Canceled']

  if (!subscription.subscription) return ['yellow', 'Awaiting Payment']

  return ['green', 'Active']
}
