import React, { useEffect, PropsWithChildren } from 'react'
import { Router as ReachRouter, Redirect, RouteComponentProps, navigate } from '@reach/router'
import useAuth, { useAuthState } from 'src/store/auth'
import { Column } from 'src/ui'
import Login from 'src/auth/login'
import { CustomerLogin } from 'src/customer/login'

const DEFAULT_ROUTE = '/schedule'
const DEFAULT_CUSTOMER_ROUTE = '/customer'

export type AuthRouteProps = RouteComponentProps & {
  as: React.ComponentType<any>
  [key: string]: any // maybe lock this down later
}

const AuthRoute = ({ as: RouteComponent, ...rest }: AuthRouteProps) => {
  const auth = useAuthState()

  if (!hasAuth(auth)) return <Login />

  return <RouteComponent {...{ auth }} {...rest} />
}

const EmployeeRoute = ({ as: RouteComponent, ...rest }: AuthRouteProps) => {
  const auth = useAuthState()

  if (!hasAuth(auth)) return <Login />
  // @ts-ignore
  if (isCustomer(auth.user)) return <Redirect to="/customer" noThrow />

  return <RouteComponent {...{ auth }} {...rest} />
}

const CustomerRoute = ({ as: RouteComponent, ...rest }: AuthRouteProps) => {
  const auth = useAuthState()

  if (!hasAuth(auth)) return <CustomerLogin />
  // @ts-ignore
  if (!isCustomer(auth.user)) return <Redirect to="/" noThrow />

  return <RouteComponent {...{ auth }} {...rest} />
}

const DriverRoute = ({ as: RouteComponent, ...rest }: AuthRouteProps) => {
  const auth = useAuthState()

  if (!hasAuth(auth)) return <Login />
  // @ts-ignore
  if (isCustomer(auth.user)) return <Redirect to="/customer" noThrow />
  // @ts-ignore
  if (auth.user?.role !== 'driver') return <Redirect to="/" noThrow />

  return <RouteComponent {...{ auth }} {...rest} />
}

const AdminRoute = ({ as: RouteComponent, ...rest }: AuthRouteProps) => {
  const auth = useAuthState()

  if (!hasAuth(auth)) return <Login />
  // @ts-ignore
  if (isCustomer(auth.user)) return <Redirect to="/customer" noThrow />
  // @ts-ignore
  if (auth.user?.role !== 'admin') return <Redirect to="/" noThrow />

  return <RouteComponent {...{ auth }} {...rest} />
}
const SuperAdminRoute = ({ as: RouteComponent, ...rest }: AuthRouteProps) => {
  const auth = useAuthState()

  if (!hasAuth(auth)) return <Login />
  // @ts-ignore
  if (isCustomer(auth.user)) return <Redirect to="/customer" noThrow />
  if (auth.user?.role !== 'admin' || auth.user?.email !== 'admin@falcon.vegas') {
    // @ts-ignore
    return <Redirect to="/" noThrow />
  }

  return <RouteComponent {...{ auth }} {...rest} />
}

const PublicRoute = ({ as: RouteComponent, ...rest }: AuthRouteProps) => {
  const auth = useAuthState()

  if (!hasAuth(auth)) return <RouteComponent {...rest} />
  // @ts-ignore
  if (isCustomer(auth?.user)) return <Redirect to={DEFAULT_CUSTOMER_ROUTE} noThrow />

  // @ts-ignore
  return <Redirect to={DEFAULT_ROUTE} noThrow />
}

const RootRedirect = () => (
  // @ts-ignore
  <Redirect to={DEFAULT_ROUTE} noThrow />
)

const Logout = () => {
  // @ts-ignore
  const logout = useAuth((state) => state.logout)

  useEffect(() => {
    logout()
  }, [logout])

  return null
}

interface FlexRouterProps {
  basepath?: string
  styles?: { [key: string]: any }
}

const FlexRouter = ({ children, styles = {} }: PropsWithChildren<FlexRouterProps>) => (
  // @ts-ignore
  <ReachRouter
    primary={false}
    css={{
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      ...styles,
    }}
  >
    {children}
  </ReachRouter>
)

const NotFound = (_props: RouteComponentProps) => (
  <Column flex={1} justifyContent="center" alignItems="center" fontSize={36}>
    Not Found
  </Column>
)

const Empty = (_props: RouteComponentProps) => null

///////////////////////////////////////////////////////////////////////////////

const replace = (url: string) => navigate(url, { replace: true })

function hasAuth(auth: AuthState) {
  return !!auth?.token
}

function isCustomer(user: User | Customer) {
  return user?.entity === 'Customer'
}

export {
  navigate,
  replace,
  FlexRouter,
  NotFound,
  Empty,
  RootRedirect,
  Redirect,
  PublicRoute,
  AuthRoute,
  EmployeeRoute,
  AdminRoute,
  SuperAdminRoute,
  DriverRoute,
  CustomerRoute,
  Logout,
}
