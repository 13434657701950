import React from 'react'
import { Row, Column } from 'src/ui'

export function DetailItem({ label, value }) {
  return (
    <Column minW="50px">
      <Row fontSize="xs" fontWeight={500} color="gray.600" mb={1}>
        {label}
      </Row>
      <Row>{value}</Row>
    </Column>
  )
}
