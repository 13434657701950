import React, { useState, useCallback } from 'react'
import { Box, Row, Button, Collapse, Icon, Stack } from 'src/ui'
import { SubscriptionRow } from 'src/billing/shared/subscription-row'
import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons'

export function Subscriptions({
  subscriptions,
  allowCancel = false,
  isCanceling = false,
  cancelSubscription = null,
}) {
  const [isExpanded, setIsExpanded] = useState(false)
  const toggleExpand = useCallback(() => setIsExpanded((v) => !v), [])

  const activeSubscriptions = subscriptions?.filter((subscription) => !subscription.canceled_at)
  const canceledSubscriptions = subscriptions?.filter((subscription) => !!subscription.canceled_at)

  return (
    <Stack spacing={3}>
      {activeSubscriptions?.map((subscription) => (
        <SubscriptionRow
          key={subscription.id}
          {...{
            title: 'Subscription',
            allowCancel,
            subscription,
            isCanceling,
            cancelSubscription,
          }}
        />
      ))}

      {canceledSubscriptions?.length > 0 && (
        <>
          <Row justifyContent="center" alignItems="center">
            <Box>
              <Button
                leftIcon={
                  <Icon as={isExpanded ? ChevronDownIcon : ChevronRightIcon} boxSize="1rem" />
                }
                size="xs"
                variant="link"
                aria-label="Toggle Canceled Subscriptions"
                onClick={toggleExpand}
              >
                {isExpanded ? 'Hide' : 'Show'} Canceled Subscriptions
              </Button>
            </Box>
          </Row>

          <Collapse in={isExpanded}>
            {canceledSubscriptions?.map((subscription) => (
              <SubscriptionRow
                key={subscription.id}
                {...{
                  title: 'Canceled Subscription',
                  subscription,
                  isCanceling,
                  cancelSubscription,
                }}
              />
            ))}
          </Collapse>
        </>
      )}
    </Stack>
  )
}
