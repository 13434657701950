import { useState, useCallback } from 'react'
import { get, set } from 'lodash'
import * as ls from 'src/utils/local-storage'

const INITIAL_STATE = {
  version: '2',
  deliveries: {
    delivery_table: {
      hidden: ['id', 'vendor_id_starts_at'],
    },
    calendar: {
      status_filter: ['scheduled', 'in-progress', 'completed'],
    },
  },
}

export function useSettings() {
  const [settings, setSettings] = useState<typeof INITIAL_STATE>(() => {
    const stored = ls.getLocalStorageKey(ls.LS_KEYS.SETTINGS)

    if (stored && stored?.version !== INITIAL_STATE.version) {
      // Anything without a root-level `version` is considered invalid
      ls.removeLocalStorageKey(ls.LS_KEYS.SETTINGS)
      return INITIAL_STATE
    }

    return stored || INITIAL_STATE
  })

  return {
    settings,
    setSettings,
    get: useCallback(
      (key: string, defaultValue: any = null) => get(settings, key, defaultValue),
      [settings]
    ),
    set: useCallback(
      (key: string, value: any) =>
        setSettings((state) => {
          const next = set({ ...state }, key, value)

          ls.setLocalStorageKey(ls.LS_KEYS.SETTINGS, next)

          return next
        }),
      []
    ),
  }
}
