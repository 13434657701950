import React, { lazy, Suspense } from 'react'
import { Helmet } from 'react-helmet'
import { QueryClientProvider } from 'react-query'
// import { ReactQueryDevtools } from 'react-query/devtools'
import { ChakraProvider } from '@chakra-ui/react'
import { queryClient } from 'src/queries/client'
import { GlobalStyles } from 'src/styles'
import * as Routing from 'src/routing'
import ErrorBoundary from 'src/app/error-boundary'
import Layout from 'src/app/layout'
import Login from 'src/auth/login'
import Customer from 'src/pages/customer'
import Schedule from 'src/pages/schedule'
import Checklist from 'src/pages/checklist'
import Tasks from 'src/pages/tasks'
import Config from 'src/pages/config'
import Manage from 'src/pages/manage'

const ReportsPage = lazy(() => import('src/pages/reports'))

function LazyReportsPage(props) {
  return (
    <Suspense fallback={null}>
      <ReportsPage {...props} />
    </Suspense>
  )
}

function App() {
  return (
    <ChakraProvider>
      <GlobalStyles />
      <Helmet>
        <title>Falcon</title>
      </Helmet>

      <ErrorBoundary>
        <>
          <Layout>
            <Routing.FlexRouter styles={{ height: '100%' }}>
              <Customer path="/customer/*" />
              <Routing.PublicRoute path="/login" as={Login} />
              <Routing.AuthRoute path="/logout" as={Routing.Logout} />
              <Routing.AuthRoute path="/" as={Routing.RootRedirect} />
              <Routing.EmployeeRoute path="/schedule/*" as={Schedule} />
              <Routing.DriverRoute path="/checklist/*" as={Checklist} />
              <Routing.AdminRoute path="/deliveries/*" as={Tasks} />
              <Routing.AdminRoute path="/reports/*" as={LazyReportsPage} />
              <Routing.AdminRoute path="/manage/*" as={Manage} />
              <Routing.SuperAdminRoute path="/config/*" as={Config} />
              <Routing.NotFound default />
            </Routing.FlexRouter>
          </Layout>
        </>
      </ErrorBoundary>
    </ChakraProvider>
  )
}

export default function AppWrapper() {
  return (
    <QueryClientProvider client={queryClient}>
      <App />
      {/* <ReactQueryDevtools /> */}
    </QueryClientProvider>
  )
}
