import React, { useState, useCallback } from 'react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { useQueryClient } from 'react-query'
import {
  Box,
  Row,
  Button,
  ButtonGroup,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  Fade,
  Menu,
  MenuButton,
  MenuDivider,
  MenuList,
  MenuItem,
  Spacer,
  useToast,
} from 'src/ui'
import { useDeliveryMutations } from 'src/queries'
import TaskDetails from 'src/deliveries/task-details'
import TaskForm from 'src/deliveries/task-form'
import { useCalendarDelivery } from 'src/store/calendar'
import * as deliveryUtils from 'src/deliveries/utils'
import { TASK_STATUSES, PAYMENT_METHODS } from 'src/utils/const'

interface TaskDrawerProps {
  tasks: Delivery[]
}

export default function TaskDrawer({ tasks }: TaskDrawerProps) {
  const {
    delivery: storeDelivery,
    status,
    closeSidebar,
    startEditing,
    stopEditing,
  } = useCalendarDelivery()
  const scheduleDeliveryId = storeDelivery?.id
  const delivery = tasks.find((d) => d.id === scheduleDeliveryId) || storeDelivery

  const isOpen = status !== 'idle'
  const header = <Header {...{ delivery, closeSidebar }} />
  const footer = <Footer {...{ status, closeSidebar, startEditing, stopEditing }} />

  return (
    <Drawer isOpen={isOpen} placement="left" onClose={closeSidebar} size="md">
      <DrawerOverlay />

      <DrawerContent>
        {header && <DrawerHeader bg="gray.700">{header}</DrawerHeader>}
        <DrawerBody width="100%">
          {!delivery && <Box>Select a delivery</Box>}

          <Fade in={delivery && status === 'info'} unmountOnExit>
            <TaskDetails delivery={delivery as TaskWithService} />
          </Fade>

          <Fade in={delivery && status === 'edit'} unmountOnExit>
            <TaskForm delivery={delivery as TaskWithService} stopEditing={stopEditing} />
          </Fade>
        </DrawerBody>
        {footer && <DrawerFooter>{footer}</DrawerFooter>}
      </DrawerContent>
    </Drawer>
  )
}

///////////////////////////////////////////////////////////////////////////////

function Header({ delivery, closeSidebar }) {
  const { updateDelivery, deleteDelivery } = useDeliveryMutations()
  const isSubscription = deliveryUtils.isTaskSubscription(delivery)
  const hasStripeBilling = delivery.payment_method === 'stripe'

  return (
    <Row alignItems="center">
      {delivery?.id ? (
        <>
          <DeleteButton
            {...{
              delivery,
              closeSidebar,
              deleteObject: deleteDelivery,
            }}
          />

          <Spacer />

          <Box mr={2}>
            {!isSubscription && !hasStripeBilling && (
              <PaymentStatusMenuButton {...{ obj: delivery, updateObject: updateDelivery }} />
            )}

            {/*
            <Badge py={2} px={4} borderRadius={4}>
              Subscription
            </Badge>
            */}
          </Box>

          <Box>
            <StatusMenuButton {...{ obj: delivery, updateObject: updateDelivery }} />
          </Box>
        </>
      ) : (
        <Box color="white">Create New Task</Box>
      )}
    </Row>
  )
}

///////////////////////////////////////////////////////////////////////////////

function Footer({ status, closeSidebar, startEditing, stopEditing }) {
  return (
    <ButtonGroup spacing={2}>
      {status === 'info' && (
        <>
          <Button width="100px" size="md" variant="ghost" onClick={closeSidebar}>
            Close
          </Button>

          <Button size="md" colorScheme="purple" width="100px" onClick={() => startEditing()}>
            Edit
          </Button>
        </>
      )}

      {status === 'edit' && (
        <>
          <Button width="100px" size="md" variant="ghost" onClick={stopEditing}>
            Cancel
          </Button>

          <Button
            type="submit"
            form="delivery-form"
            width="100px"
            size="md"
            colorScheme="purple"
            // isLoading={formikBag.isSubmitting}
            // onClick={formikBag.submitForm}
          >
            Save
          </Button>
        </>
      )}
    </ButtonGroup>
  )
}

///////////////////////////////////////////////////////////////////////////////

function DeleteButton({ delivery, closeSidebar, deleteObject }) {
  const toast = useToast()
  const queryClient = useQueryClient()
  const [isDeleting, setIsDeleting] = useState(false)
  const [isAttemptingDelete, setIsAttemptingDelete] = useState(false)

  return (
    <>
      {isAttemptingDelete ? (
        <Row alignItems="center">
          <Button
            size="sm"
            // variant="ghost"
            mr={2}
            isDisabled={isDeleting}
            onClick={() => setIsAttemptingDelete(false)}
          >
            Cancel
          </Button>

          <Button
            size="sm"
            width="72px"
            colorScheme="red"
            isLoading={isDeleting}
            isDisabled={isDeleting}
            onClick={async () => {
              setIsDeleting(true)

              deleteObject(
                { id: delivery.id },
                {
                  onSuccess: async (_response, _args) => {
                    await queryClient.invalidateQueries('tasks')

                    closeSidebar()

                    toast({
                      title: 'Success',
                      description: 'Delivery Deleted',
                      status: 'success',
                      duration: 4000,
                      isClosable: false,
                      position: 'top',
                    })
                  },

                  onError: async (...args) => {
                    toast({
                      title: 'Whoops',
                      description: 'Unable to delete delivery',
                      status: 'error',
                      duration: 4000,
                      isClosable: false,
                      position: 'top',
                    })
                  },

                  onSettled: async (...args) => setIsDeleting(false),
                }
              )
            }}
          >
            Delete
          </Button>
        </Row>
      ) : (
        <Row alignItems="center">
          <Button size="sm" width="72px" mr={2} onClick={() => setIsAttemptingDelete(true)}>
            Delete
          </Button>
        </Row>
      )}
    </>
  )
}

///////////////////////////////////////////////////////////////////////////////

function PaymentStatusMenuButton({ obj, updateObject }) {
  const { updateObjectHandler, isLoading } = useUpdateObject({ updateObject })

  return (
    <Menu placement="bottom-end">
      <MenuButton
        as={Button}
        size="sm"
        isLoading={isLoading}
        rightIcon={<ChevronDownIcon />}
        colorScheme={obj.is_paid ? 'green' : undefined}
      >
        {obj.is_paid ? 'Paid' : 'Unpaid'} - {PAYMENT_METHODS[obj.payment_method] || 'Unknown'}
      </MenuButton>

      <MenuList fontSize={14}>
        {!obj.is_paid && (
          <MenuItem onClick={() => updateObjectHandler({ id: obj.id, is_paid: true })}>
            Mark Paid
          </MenuItem>
        )}
        {obj.is_paid && (
          <MenuItem onClick={() => updateObjectHandler({ id: obj.id, is_paid: false })}>
            Mark Unpaid
          </MenuItem>
        )}

        <MenuDivider />

        {Object.keys(PAYMENT_METHODS)
          .filter((method) => method !== obj.payment_method)
          .map((method) => (
            <MenuItem
              key={method}
              onClick={() => updateObjectHandler({ id: obj.id, payment_method: method })}
            >
              {PAYMENT_METHODS[method]}
            </MenuItem>
          ))}
      </MenuList>
    </Menu>
  )
}

///////////////////////////////////////////////////////////////////////////////

function StatusMenuButton({ obj, updateObject }) {
  const { updateObjectHandler, isLoading } = useUpdateObject({ updateObject })

  return (
    <Menu placement="bottom-end">
      <MenuButton as={Button} size="sm" rightIcon={<ChevronDownIcon />} isLoading={isLoading}>
        {TASK_STATUSES[obj.status]}
      </MenuButton>

      <MenuList fontSize={14}>
        {Object.keys(TASK_STATUSES)
          .filter((status) => status !== obj.status)
          .map((status) => (
            <MenuItem key={status} onClick={() => updateObjectHandler({ id: obj.id, status })}>
              {TASK_STATUSES[status]}
            </MenuItem>
          ))}
      </MenuList>
    </Menu>
  )
}

function useUpdateObject({ updateObject }) {
  const toast = useToast()
  const queryClient = useQueryClient()
  const [isLoading, setIsLoading] = useState(false)

  const updateObjectHandler = useCallback(
    async (args) => {
      setIsLoading(true)

      updateObject(args, {
        onSuccess: async (_response, _args) => {
          await queryClient.invalidateQueries('tasks')

          toast({
            title: 'Success',
            description: 'Task Updated',
            status: 'success',
            duration: 4000,
            isClosable: false,
            position: 'top',
          })
        },

        onError: async (...args) => {
          toast({
            title: 'Whoops',
            description: 'Unable to save task',
            status: 'error',
            duration: 4000,
            isClosable: false,
            position: 'top',
          })
        },

        onSettled: async (...args) => setIsLoading(false),
      })
    },
    [toast, updateObject, setIsLoading, queryClient]
  )

  return { updateObjectHandler, isLoading, setIsLoading }
}
