import React from 'react'
import { Link } from '@chakra-ui/react'
import { Link as ReachLink } from '@reach/router'

const NavLink = ({ to, active = false, children }) => {
  return (
    <Link
      as={ReachLink}
      to={to}
      display="block"
      px={2}
      py={1}
      mx={2}
      borderBottom={active ? `2px solid #8888FC` : '2px solid transparent'}
      textAlign="center"
      transition="border-bottom 0.3s"
      _hover={{
        textDecoration: 'none',
        borderBottom: '2px solid #8888fc',
      }}
      _focus={{
        textDecoration: 'none',
        borderBottom: '2px solid #8888fc',
      }}
    >
      {children}
    </Link>
  )
}

export default NavLink
