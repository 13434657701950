import React, { useRef } from 'react'
import { useQueryClient } from 'react-query'
import { Formik, Form, Field, FormikProps } from 'formik'
import * as Yup from 'yup'
import { GithubPicker } from 'react-color'
import { Box, Row, Column, Button, ButtonGroup, Avatar, FormikElements, Modal } from 'src/ui'

interface FormValues {
  name: string
  email: string
  password: string
  role: string
  default_vehicle_id?: string
  avatar_color: string
}

interface UserFormProps {
  isOpen: boolean
  vehicles: Vehicle[]
  obj?: User
  saveObj: any
  onDismiss: () => void
}

const LoginSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  email: Yup.string().required('Required'),
  password: Yup.string(),
  role: Yup.string().required('Required'),
  avatar_color: Yup.string(),
})

const UserForm = ({ isOpen, vehicles, obj, saveObj, onDismiss }: UserFormProps) => {
  const dialogRef = useRef(null)
  const queryClient = useQueryClient()

  return (
    <Formik
      initialValues={{
        name: obj?.name || '',
        email: obj?.email || '',
        password: '',
        role: obj?.role || '',
        default_vehicle_id: obj?.default_vehicle_id || '',
        avatar_color: obj?.avatar_color || '6f6ffb',
      }}
      validationSchema={LoginSchema}
      enableReinitialize
      onSubmit={async (values: FormValues, formikActions: FormikProps<FormValues>) => {
        if (!values.password) delete values.password
        if (values.role !== 'driver') delete values.default_vehicle_id

        saveObj(
          {
            id: obj?.id, // will be undefined on new users
            name: values.name,
            email: values.email,
            password: values.password,
            role: values.role,
            default_vehicle_id: values.default_vehicle_id,
            avatar_color: values.avatar_color,
          },
          {
            onSuccess: async () => {
              await queryClient.invalidateQueries('users')
              formikActions.resetForm()
              onDismiss()
            },
            onError: async () => null,
            onSettled: () => {
              formikActions.setSubmitting(false)
            },
          }
        )
      }}
    >
      {(formikBag: FormikProps<FormValues>) => (
        <Modal
          isOpen={isOpen}
          title={obj?.id ? 'Update User' : 'Create User'}
          onClose={() => {
            formikBag.resetForm()
            onDismiss()
          }}
          footer={
            <ButtonGroup spacing={2}>
              <Button
                size="sm"
                variant="ghost"
                ref={dialogRef}
                onClick={() => {
                  formikBag.resetForm()
                  onDismiss()
                }}
              >
                Cancel
              </Button>

              <Button
                size="sm"
                colorScheme="purple"
                isLoading={formikBag.isSubmitting}
                onClick={formikBag.submitForm}
              >
                Save
              </Button>
            </ButtonGroup>
          }
        >
          <Form>
            <Column width="400">
              <Row alignItems="center" pb={4}>
                <Box flex={1}>
                  <Field name="avatar_color" component={AvatarPicker} />
                </Box>

                {obj?.created_at && (
                  <Box>
                    <TimeLabel
                      label="Created"
                      value={new Date(obj?.created_at).toLocaleDateString()}
                    />
                    {obj?.updated_at && (
                      <>
                        &nbsp;&nbsp;-&nbsp;&nbsp;
                        <TimeLabel
                          label="Updated"
                          value={new Date(obj?.updated_at).toLocaleDateString()}
                        />
                      </>
                    )}
                  </Box>
                )}
              </Row>

              <Column maxWidth="400">
                <Box height="72px" mb={2}>
                  <Field
                    id="name"
                    name="name"
                    label="Name"
                    size="sm"
                    component={FormikElements.Input}
                  />
                </Box>

                <Box height="72px" mb={2}>
                  <Field
                    id="email"
                    name="email"
                    label="Email"
                    size="sm"
                    component={FormikElements.Input}
                  />
                </Box>

                <Box height="72px" mb={0}>
                  <Field
                    type="password"
                    id="password"
                    name="password"
                    label="Password"
                    size="sm"
                    component={FormikElements.Input}
                  />
                </Box>

                <Box height="72px" mb={2}>
                  <Field
                    id="role"
                    name="role"
                    label="Role"
                    selectProps={{
                      width: '100%',
                      disabled: false,
                    }}
                    component={FormikElements.Select}
                    options={[
                      { value: 'admin', label: 'Admin' },
                      { value: 'driver', label: 'Driver' },
                    ]}
                  />
                </Box>

                {formikBag.values.role === 'driver' && (
                  <Box height="72px" mb={2}>
                    <Field
                      id="default_vehicle_id"
                      name="default_vehicle_id"
                      label="Default Vehicle"
                      selectProps={{
                        width: '100%',
                        disabled: false,
                      }}
                      component={FormikElements.Select}
                      options={vehicles.map((vehicle) => ({
                        value: vehicle.id,
                        label: vehicle.name,
                      }))}
                    />
                  </Box>
                )}
              </Column>
            </Column>
          </Form>
        </Modal>
      )}
    </Formik>
  )
}

export default UserForm

///////////////////////////////////////////////////////////////////////////////

const TimeLabel = ({ label, value }) => {
  return (
    <small>
      <Box color="#777">{label}</Box> {value}
    </small>
  )
}

const AvatarPicker = ({ form, field }) => {
  let [isSwatching, setIsSwatching] = React.useState(false)

  return (
    <>
      <Avatar
        user={{
          name: form.values.name,
          avatar_color: field.value,
        }}
        size={42}
        onClick={() => setIsSwatching((value) => !value)}
      />

      {isSwatching && (
        <Box position="absolute" zIndex={2}>
          <Box
            css={{
              position: 'fixed',
              top: '0px',
              right: '0px',
              bottom: '0px',
              left: '0px',
            }}
            onClick={() => setIsSwatching(false)}
          />
          <GithubPicker
            width="212px"
            onChange={(color) => {
              // @todo: throttle this
              form.setFieldValue('avatar_color', color.hex.replace('#', ''))
            }}
          />
        </Box>
      )}
    </>
  )
}
