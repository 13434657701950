import React, { useMemo } from 'react'
import { Box, Row, Column, Badge, Spinner } from 'src/ui'
import * as utils from 'src/utils'
import * as deliveryUtils from 'src/deliveries/utils'
import Images, { ImageDropzone } from 'src/deliveries/images'
import { SubscriptionRow } from 'src/billing/shared/subscription-row'
import { useCustomerBillingById } from 'src/queries'

interface TaskDetailsProps {
  delivery: TaskWithService
}

export default function TaskDetails({ delivery: task }: TaskDetailsProps) {
  const { billingQuery, usesSubscriptionBilling, subscription } = useTaskInfo({ task })

  return (
    <Column>
      <Row alignItems="flex-end" justifyContent="space-between" fontSize={16} fontWeight={500}>
        <Column>
          <Box mb={2} fontSize={14}>
            {task.driver_name}
            <Box as="span" pl="8px" fontSize="12px">
              {task.vehicle_name}
            </Box>
          </Box>

          <Box fontSize={20}>
            {task.price ? utils.formatMoney(task.price) : '$ -'}

            {task.service?.task_attrs?.map((attr) => {
              if (!task[attr.name]) return null

              return (
                <Badge key={attr.name} ml={2} colorScheme={attr.color}>
                  {attr.label}
                </Badge>
              )
            })}
          </Box>
        </Column>

        <Column alignItems="flex-end">
          <Row mb={2} fontSize={14}>
            {utils.formatLocal(task.starts_at, 'ddd, MMMM D')}
          </Row>

          <Row fontSize={20}>
            {utils.formatLocal(task.starts_at, 'h:mm')}
            {' - '}
            {utils.formatLocal(task.ends_at, 'h:mm A')}
          </Row>
        </Column>
      </Row>

      {task.invoice && (
        <Row alignItems="flex-end" fontSize={14} mt={1}>
          <Box>
            <strong>Invoice</strong> {task.invoice}
          </Box>
        </Row>
      )}

      {usesSubscriptionBilling && (
        <>
          {billingQuery.isLoading && <Loading />}
          {billingQuery.isSuccess && !!subscription && (
            <Box mt={4}>
              <SubscriptionRow title="Using Subscription" subscription={subscription} />
            </Box>
          )}
        </>
      )}

      <Box my={3} height="1px" borderBottom="1px solid #eee" />

      {!!task.vendor_name && (
        <>
          <Row mb={1} justifyContent="space-between" alignItems="center">
            <Box fontSize={16} color="#888" fontWeight={500}>
              Vendor
            </Box>

            <Box fontSize={16} fontWeight={500}>
              {task.vendor_name}
            </Box>
          </Row>

          <Row justifyContent="space-between">
            <Column>
              <Box fontSize={16}>
                {task.pickup_address && task.pickup_address.split(',').slice(0, 1)}
                <br />
                {task.pickup_address && task.pickup_address.split(',').slice(1).join(', ')}
              </Box>
            </Column>

            <Column justifyContent="flex-end" alignItems="flex-end">
              <Box>{task.vendor_contact}</Box>
              <Box>{utils.prettyPhoneNumber(task.vendor_phone)}</Box>
            </Column>
          </Row>

          <Row pt={3}>{task.description}</Row>

          <Box my={3} height="1px" borderBottom="1px solid transparent" />
        </>
      )}

      <Row mb={1} justifyContent="space-between" alignItems="center">
        <Box fontSize={16} color="#888" fontWeight={500}>
          Customer
        </Box>

        <Box fontSize={16} fontWeight={500}>
          {task.customer_name}
        </Box>
      </Row>

      <Row justifyContent="space-between">
        <Column>
          <Box fontSize={16}>
            {task.delivery_address && task.delivery_address.split(',').slice(0, 1)}
            <br />
            {task.delivery_address && task.delivery_address.split(',').slice(1).join(', ')}
          </Box>
        </Column>

        <Column justifyContent="flex-end" alignItems="flex-end">
          <Box>{utils.prettyPhoneNumber(task.customer_phone)}</Box>
          <Box>{task.customer_email}</Box>
        </Column>
      </Row>

      <Box pt={3} mb={2}>
        {task.instructions}
      </Box>

      <Divider />

      <Row mb={1} justifyContent="space-between" alignItems="center">
        <Box fontSize={16} color="#888" fontWeight={500}>
          {!task.images?.length && 'No'} Images
        </Box>

        <Box>
          <ImageDropzone deliveryId={task.id} showEmptyState={false} />
        </Box>
      </Row>

      {!!task.images?.length && <Images delivery={task} />}
    </Column>
  )
}

function Divider() {
  return <Box height="1px" my={4} borderBottom="1px solid #eee" />
}

function Loading() {
  return (
    <Row width="100%" mt={8} justifyContent="center">
      <Spinner speed="0.65s" size="md" />
    </Row>
  )
}

///////////////////////////////////////////////////////////////////////////////////////////////////

function useTaskInfo({ task }: { task: TaskWithService }) {
  const customerId = task.customer_id
  const usesSubscriptionBilling = deliveryUtils.isTaskSubscription(task)

  const subscriptionId = usesSubscriptionBilling
    ? task.service_variant_id?.replace('subscription#', '')
    : null

  const queryIsEnabled = !!task.customer_id && !!subscriptionId
  const billingQuery = useCustomerBillingById({ customerId, enabled: queryIsEnabled })

  const customerSubscriptions = billingQuery.data?.subscriptions

  return {
    billingQuery,

    usesSubscriptionBilling,

    subscription: useMemo(() => {
      if (!customerSubscriptions || customerSubscriptions.length === 0) return null

      return customerSubscriptions.find((s) => s.id === subscriptionId)
    }, [subscriptionId, customerSubscriptions]),
  }
}

// function Error() {
//   return (
//     <Row width="100%" mt="20vh" justifyContent="center">
//       There was an error loading billing information.
//     </Row>
//   )
// }
