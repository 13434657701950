import React from 'react'
import { ChevronLeftIcon, ChevronRightIcon, SmallAddIcon, SettingsIcon } from '@chakra-ui/icons'
import {
  Box,
  Row,
  Column,
  Button,
  ButtonGroup,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
} from 'src/ui'
import { useCalendarView } from 'src/store/calendar'
import { useAuthState } from 'src/store/auth'
import {
  shouldShowJumpToToday,
  STEP_FUNCTIONS,
  viewLabel,
  createNewObject,
} from 'src/schedule/calendars/utils'
import { TASK_STATUSES } from 'src/utils/const'
import * as utils from 'src/utils'

interface ToolbarProps {
  isMobile?: boolean
  statusFilters: string[]
  setStatusFilters: (a: string[]) => void
}

export default function Toolbar({ isMobile, statusFilters, setStatusFilters }: ToolbarProps) {
  const { isAdmin } = useAuthState()
  const { view, setView, markerDate, setMarkerDate, setDeliveryToEdit } = useCalendarView()

  return (
    <Row width="100%" justifyContent="center" mb={4}>
      <Column
        position="relative"
        maxWidth={view === 'day' ? 1200 : undefined}
        flex={1}
        alignItems="center"
      >
        {!isMobile && (
          <Box alignSelf="flex-end">
            <ButtonGroup
              bg="#fff"
              spacing={0}
              css={{
                button: {
                  width: 80,
                  borderRadius: 0,
                },
                'button:not(:last-of-type)': {
                  borderRight: 'none',
                },
                'button:first-of-type': {
                  borderTopLeftRadius: '0.25rem',
                  borderBottomLeftRadius: '0.25rem',
                },
                'button:last-of-type': {
                  borderTopRightRadius: '0.25rem',
                  borderBottomRightRadius: '0.25rem',
                },
              }}
            >
              <Button
                size="sm"
                colorScheme={view === 'week' ? 'purple' : undefined}
                variant={view !== 'week' ? 'outline' : undefined}
                onClick={() => setView('week')}
              >
                Week
              </Button>
              <Button
                size="sm"
                colorScheme={view === '3day' ? 'purple' : undefined}
                variant={view !== '3day' ? 'outline' : undefined}
                onClick={() => setView('3day')}
              >
                3-Day
              </Button>
              <Button
                size="sm"
                colorScheme={view === 'day' ? 'purple' : undefined}
                variant={view !== 'day' ? 'outline' : undefined}
                onClick={() => setView('day')}
              >
                Day
              </Button>
            </ButtonGroup>
          </Box>
        )}

        {view !== 'list' && (
          <>
            <Row width="100%" alignItems="center">
              <Row flex={1} justifyContent="center">
                {isMobile && (
                  <Box>
                    <CalendarSettings
                      isMobile={isMobile}
                      eventFilters={statusFilters}
                      setEventFilters={setStatusFilters}
                    />
                  </Box>
                )}
              </Row>

              <Row
                flex={1}
                justifyContent="center"
                alignItems="center"
                ml={isMobile ? undefined : '72px'}
              >
                <Box>
                  <IconButton
                    variant="ghost"
                    aria-label="previous"
                    icon={<ChevronLeftIcon />}
                    size="md"
                    fontSize={32}
                    children={null}
                    onClick={() => setMarkerDate(STEP_FUNCTIONS[view].prev(markerDate))}
                  />
                </Box>

                <Box minWidth="160px" textAlign="center" fontSize={18} fontWeight={600}>
                  {viewLabel({ view, markerDate })}
                </Box>

                <Box>
                  <IconButton
                    variant="ghost"
                    aria-label="next"
                    icon={<ChevronRightIcon />}
                    size="md"
                    fontSize={32}
                    children={null}
                    onClick={() => setMarkerDate(STEP_FUNCTIONS[view].next(markerDate))}
                  />
                </Box>
              </Row>

              <Row flex={1} justifyContent="flex-end">
                {!isMobile && (
                  <Box>
                    <CalendarSettings
                      isMobile={isMobile}
                      eventFilters={statusFilters}
                      setEventFilters={setStatusFilters}
                    />
                  </Box>
                )}
              </Row>

              {isMobile && isAdmin && (
                <Box position="absolute" right="16px">
                  <IconButton
                    isRound
                    colorScheme="purple"
                    aria-label="next"
                    icon={<SmallAddIcon />}
                    // size="md"
                    fontSize={32}
                    children={null}
                    onClick={() => {
                      setDeliveryToEdit(
                        createNewObject({
                          start: markerDate.clone().hour(12),
                          end: markerDate.clone().hour(12),
                        })
                      )
                    }}
                  />
                </Box>
              )}
            </Row>

            <Box height="24px" ml={isMobile ? undefined : '72px'}>
              {shouldShowJumpToToday({ view, markerDate }) && (
                <Button
                  size="xs"
                  variant="link"
                  // leftIcon="external-link"
                  onClick={() => setMarkerDate(utils.momentLocalizedParse())}
                >
                  Jump to Today
                </Button>
              )}
            </Box>
          </>
        )}
      </Column>
    </Row>
  )
}

///////////////////////////////////////////////////////////////////////////////////////////////////

const WrappedMenuButton = (props) => (
  <MenuButton as={IconButton} {...props}>
    MenuItem
  </MenuButton>
)

const CalendarSettings = ({ isMobile, eventFilters, setEventFilters }) => {
  return (
    <Menu closeOnSelect={false} placement="bottom-end">
      <WrappedMenuButton
        icon={<SettingsIcon />}
        size={isMobile ? 'md' : 'sm'}
        variant={isMobile ? 'solid' : 'outline'}
        isRound={isMobile}
        colorScheme="purple"
        aria-label="Settings"
      >
        MenuItem
      </WrappedMenuButton>

      <MenuList minWidth="240px">
        <MenuOptionGroup
          title="Filter by Status"
          type="checkbox"
          value={eventFilters}
          onChange={(value) => setEventFilters(value)}
        >
          {Object.keys(TASK_STATUSES).map((key) => {
            return (
              <MenuItemOption key={key} value={key}>
                {TASK_STATUSES[key]}
              </MenuItemOption>
            )
          })}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  )
}
