import { navigate } from '@reach/router'
import create from 'zustand'
import { combine } from 'zustand/middleware'
import { queryClient } from 'src/queries/client'
import * as api from 'src/api'
import * as ls from 'src/utils/local-storage'

let storedAuth: AuthState = ls.getLocalStorageKey(ls.LS_KEYS.AUTH)

const useAuth = create(
  combine(
    {
      user: storedAuth?.user,
      token: storedAuth?.token,
    },
    (set, get) => ({
      // Employee authentication
      authenticate: async ({
        email,
        type,
        password,
        emailAuthToken,
        successRedirectPath = '/',
      }: {
        email: string
        type?: string
        password?: string
        emailAuthToken?: string
        successRedirectPath?: string
      }) => {
        console.log('src/store - authenticate', { type, email, emailAuthToken })

        let [err, response] = await api.authenticate({
          type: type ?? 'emp',
          email,
          password,
          email_auth_token: emailAuthToken,
        })

        if (err) return [err, null]

        let { user, token } = response.data

        ls.setLocalStorageKey(ls.LS_KEYS.AUTH, { user, token })

        api.setAxiosHeader('Authorization', `Bearer ${token}`)

        if (type !== 'cus') {
          let [, bootstrapData] = await api.batchBootstrap()
          if (bootstrapData) queryClient.setQueryData(['batch-bootstrap'], bootstrapData.data)
        }

        set(() => ({ user, token }))

        navigate(successRedirectPath)

        return [null, response]
      },

      // customer authentication
      authenticateCustomer: async ({ email }) => {
        console.log('src/store - authenticateCustomer', { email })

        const [err, response] = await api.authenticateEmailToken({ email })

        // @ts-ignore
        if (err) return [err?.response?.data?.error || 'Invalid email', null]

        return [null, response.data?.message]
      },

      // Generic Logout
      logout: () => {
        console.log('src/store - logout')

        const user = get().user
        const loginPath = user.entity === 'Customer' ? '/customer/login' : '/login'

        ls.removeLocalStorageKey(ls.LS_KEYS.AUTH)

        api.removeAxiosHeader('Authorization')
        api.removeAxiosAuthInterceptor()

        set(() => ({ user: null, token: null }))

        navigate(loginPath)
      },
    })
  )
)

export default useAuth

export const useAuthState = () => useAuth(authSelector)
export const useAuthHandlers = () =>
  useAuth((...args) => {
    console.log('useAuthHandlers - args', args)
    return {}
  })

export const authSelector = (state) => ({
  user: state.user,
  token: state.token,
  isAdmin: state.user?.role === 'admin',
})
