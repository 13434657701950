import { Link } from '@reach/router'
import {
  Button,
  // ButtonProps,
  forwardRef,
} from '@chakra-ui/react'

// interface ButtonLinkProps extends ButtonProps {
//   disabled?: boolean
//   scale?: string
//   to: string
// }

const ButtonLink = forwardRef(({ variant, ...props }, ref: any) => (
  <Button
    as={Link}
    ref={ref}
    variant={variant}
    _hover={{
      textDecoration: variant !== 'link' ? 'none' : undefined,
    }}
    {...props}
  />
))

export default ButtonLink
