import React from 'react'
import { Box, forwardRef } from '@chakra-ui/react'

const Column = forwardRef((props, ref) => <Box {...props} ref={ref} />)

Column.defaultProps = {
  display: 'flex',
  flexDirection: 'column',
}

Column.displayName = 'Column'

export default Column
