import { useQuery, useMutation } from 'react-query'
import * as api from 'src/api'
import config from 'src/utils/config'

// COMMON
///////////////////////////////////////////////////////////////////////////////////////////////////

// SHARED
///////////////////////////////////////////////////////////////////////////////////////////////////

const NEVER_STALE = {
  refetchOnMount: false,
  staleTime: Infinity,
  cacheTime: Infinity,
}

export const useMe = () => {
  return useQuery(
    ['me'],

    async (_key) => {
      const [err, response] = await api.me()

      if (err) throw err

      return response.data.user
    },

    NEVER_STALE
  )
}

export const useTenant = () => {
  return useQuery(
    ['tenant'],

    async (_key) => {
      const [err, response] = await api.tenant()

      if (err) throw err

      return response.data
    },

    NEVER_STALE
  )
}

// USERS
///////////////////////////////////////////////////////////////////////////////////////////////////

export const useBatchBootstrap = () => {
  return useQuery(
    ['batch-bootstrap'],

    async (_key) => {
      const [err, response] = await api.batchBootstrap()

      if (err) throw err

      return response.data
    },

    NEVER_STALE
  )
}

// USERS
///////////////////////////////////////////////////////////////////////////////////////////////////

export const useUsers = () => {
  return useQuery(
    ['users'],

    async (_key) => {
      const [err, response] = await api.getUsers()

      if (err) throw err

      return response.data.data
    },

    NEVER_STALE
  )
}

export const useUserMutations = () => {
  return {
    createUser: useMutation(async (args: Partial<User>) => {
      const [err, response] = await api.createUser(args)

      if (err) throw err

      return response.data.data
    }).mutate,

    updateUser: useMutation(async (args: Partial<User>) => {
      const [err, response] = await api.updateUser(args)

      if (err) throw err

      return response.data.data
    }).mutate,

    deleteUser: useMutation(async ({ id }: { id: string }) => {
      const [err, response] = await api.deleteUser({ id })

      if (err) throw err

      return response.data
    }).mutate,
  }
}

// VEHICLES
///////////////////////////////////////////////////////////////////////////////////////////////////

export const useVehicles = () => {
  return useQuery(
    ['vehicles'],

    async (_key) => {
      const [err, response] = await api.getVehicles()

      if (err) throw err

      return response.data.data
    },

    NEVER_STALE
  )
}

export const useVehicleMutations = () => {
  return {
    createVehicle: useMutation(async (args: Partial<Vehicle>) => {
      const [err, response] = await api.createVehicle(args)

      if (err) throw err

      return response.data.data
    }).mutate,

    updateVehicle: useMutation(async (args: Partial<Vehicle>) => {
      const [err, response] = await api.updateVehicle(args)

      if (err) throw err

      return response.data.data
    }).mutate,

    deleteVehicle: useMutation(async ({ id }: { id: string }) => {
      const [err, response] = await api.deleteVehicle({ id })

      if (err) throw err

      return response.data
    }).mutate,
  }
}

// VENDORS
///////////////////////////////////////////////////////////////////////////////////////////////////

export const useVendors = () => {
  return useQuery(
    ['vendors'],

    async (_key) => {
      const [err, response] = await api.getVendors()

      if (err) throw err

      return response.data.data
    },

    NEVER_STALE
  )
}

export const useVendorMutations = () => {
  return {
    createVendor: useMutation(async (args: Partial<Vendor>) => {
      const [err, response] = await api.createVendor(args)

      if (err) throw err

      return response.data.data
    }).mutate,

    updateVendor: useMutation(async (args: Partial<Vendor>) => {
      const [err, response] = await api.updateVendor(args)

      if (err) throw err

      return response.data.data
    }).mutate,

    deleteVendor: useMutation(async ({ id }: { id: string }) => {
      const [err, response] = await api.deleteVendor({ id })

      if (err) throw err

      return response.data
    }).mutate,
  }
}

// CUSTOMERS
///////////////////////////////////////////////////////////////////////////////////////////////////

export const useCustomers = () => {
  return useQuery(
    ['customers'],

    async (_key) => {
      const [err, response] = await api.getCustomers()

      if (err) throw err

      return response.data.data
    },

    NEVER_STALE
  )
}
export const useCustomer = ({ id, enabled = true }) => {
  return useQuery(
    ['customers', { id }],

    async (_key) => {
      const [err, response] = await api.getCustomer(id)

      if (err) throw err

      return response.data.data
    },
    {
      // ...NEVER_STALE,
      refetchOnMount: false,
      staleTime: 120 * 1000,
      cacheTime: 120 * 1000,
      enabled,
    }
  )
}

export const useCustomerMutations = () => {
  return {
    createCustomer: useMutation(async (args: Partial<Customer>) => {
      const [err, response] = await api.createCustomer(args)

      if (err) throw err

      return response.data.data
    }).mutate,

    updateCustomer: useMutation(async (args: Partial<Customer>) => {
      const [err, response] = await api.updateCustomer(args)

      if (err) throw err

      return response.data.data
    }).mutate,

    deleteCustomer: useMutation(async ({ id }: { id: string }) => {
      const [err, response] = await api.deleteCustomer({ id })

      if (err) throw err

      return response.data
    }).mutate,

    createCustomerDocument: useMutation(async (args: DocumentCreateParams) => {
      const [err, response] = await api.createCustomerDocument(args)

      if (err) throw err

      return [err, response]
    }).mutate,

    createCustomerSubscription: useMutation(
      async (args: { customer_id: string; service_variant_id: string }) => {
        const [err, response] = await api.createCustomerSubscription(args)

        if (err) throw err

        return [err, response]
      }
    )[0],

    cancelCustomerSubscription: useMutation(
      async (args: { customer_id: string; subscription_id: string }) => {
        const [err, response] = await api.cancelCustomerSubscriptionById(args)

        if (err) throw err

        return [err, response]
      }
    )[0],
  }
}

// DOCUMENTS
///////////////////////////////////////////////////////////////////////////////////////////////////

export const useDocuments = () => {
  return useQuery(
    ['documents'],

    async (_key) => {
      const [err, response] = await api.getDocuments()

      if (err) throw err

      return response.data
    },

    NEVER_STALE
  )
}

export const useDocumentMutations = () => {
  return {
    createDocument: useMutation(async (args: Partial<TaskDocument>) => {
      const [err, response] = await api.createDocument(args)

      if (err) throw err

      return response.data
    }).mutate,

    updateDocument: useMutation(async (args: Partial<TaskDocument>) => {
      const [err, response] = await api.updateDocument(args)

      if (err) throw err

      return response.data
    }).mutate,

    deleteDocument: useMutation(async ({ id }: { id: string }) => {
      const [err, response] = await api.deleteDocument({ id })

      if (err) throw err

      return response.data
    }).mutate,
  }
}

// DRIVER SCHEDULES
///////////////////////////////////////////////////////////////////////////////////////////////////

export const useDriverSchedules = ({ range_start, range_end }) => {
  return useQuery(
    ['driver-schedules', { range_start, range_end }],

    async () => {
      const [err, response] = await api.getDriverSchedules({ range_start, range_end })

      if (err) throw err

      return response.data.data
    }
  )
}

export const useDriverScheduleMutations = () => {
  return {
    updateSchedule: useMutation(async (args: { id: string; config: boolean[] }) => {
      const [err, response] = await api.updateDriverSchedule(args)

      if (err) throw err

      return [err, response]
    }).mutate,

    updateScheduleEvent: useMutation(async (args: { id: string; date: string; type: string }) => {
      const [err, response] = await api.updateDriverScheduleEvent(args)

      if (err) throw err

      return [err, response]
    }).mutate,
  }
}

// REPORTS
///////////////////////////////////////////////////////////////////////////////////////////////////

export const useReportsHeadline = () => {
  return useQuery(
    'reports-headline',

    async (_key) => {
      const [err, response] = await api.getReportsHeadline()

      if (err) throw err

      return response.data
    }
  )
}

export const useReportsSeries = ({ start, end }) => {
  return useQuery(
    ['reports-series', { start, end }],

    async () => {
      const [err, response] = await api.getReportsSeries({ start, end })

      if (err) throw err

      return response.data
    }
  )
}

// TASKS (DELIVERIES)
///////////////////////////////////////////////////////////////////////////////////////////////////

const TASK_REFRESH_MINS = 10

interface useTasksArgs {
  range_start: string
  range_end: string
}

export const useTasks = ({ range_start, range_end }: useTasksArgs) => {
  return useQuery(
    ['tasks', { range_start, range_end }],

    async () => {
      const [err, response] = await api.getDeliveries({ range_start, range_end })

      if (err) throw err

      return response.data.data
    },
    config.NODE_ENV === 'production'
      ? {
          refetchInterval: TASK_REFRESH_MINS * 60 * 1000, // refresh every 10m
          refetchOnWindowFocus: true,
        }
      : {}
  )
}

export const useDeliveryMutations = () => {
  return {
    createDelivery: useMutation(async (args: Partial<Delivery>) => {
      const [err, response] = await api.createDelivery(args)

      if (err) throw err

      return [err, response]
    }).mutate,

    updateDelivery: useMutation(async (args: Partial<Delivery>) => {
      const [err, response] = await api.updateDelivery(args)

      if (err) throw err

      return [err, response]
    }).mutate,

    deleteDelivery: useMutation(async ({ id }: { id: string }) => {
      const [err, response] = await api.deleteDelivery({ id })

      if (err) throw err

      return [err, response]
    }).mutate,
  }
}

// CHECKLISTS
///////////////////////////////////////////////////////////////////////////////////////////////////

export const useChecklistItems = () => {
  return useQuery(
    'checklist-items',

    async (_key) => {
      const [err, response] = await api.getChecklistItems()

      if (err) throw err

      return response.data.data
    }
  )
}

export const useChecklistEntries = ({ date_driver_id }: { date_driver_id: string }) => {
  return useQuery(
    ['checklist-entry', { date_driver_id }],

    async () => {
      const [err, response] = await api.getChecklistEntriesByDateAndDriver({ date_driver_id })

      if (err) throw err

      return response.data.data.length > 0 ? response.data.data[0] : null
    }
  )
}

export const useChecklistMutations = () => {
  return {
    createChecklistEntry: useMutation(async (args: Partial<ChecklistEntry>) => {
      const [err, response] = await api.createChecklistEntry(args)

      if (err) throw err

      return [err, response]
    }).mutate,

    updateChecklistEntry: useMutation(async (args: Partial<ChecklistEntry>) => {
      const [err, response] = await api.updateChecklistEntry(args)

      if (err) throw err

      return [err, response]
    }).mutate,
  }
}

// BILLING
///////////////////////////////////////////////////////////////////////////////////////////////////

export const useCustomerBillingById = ({
  customerId,
  enabled = true,
}: {
  customerId: string
  enabled?: boolean
}) => {
  return useQuery(
    ['customer-billing', { customerId }],

    async (_key) => {
      const [err, response] = await api.getCustomerBillingById(customerId)

      if (err) throw err

      return response.data
    },
    {
      enabled,
    }
  )
}

// CLIENT
///////////////////////////////////////////////////////////////////////////////////////////////////

export const useClientMutations = () => {
  return {
    updateClient: useMutation(async (args: Partial<Customer>) => {
      const [err, response] = await api.updateClient(args)

      if (err) throw err

      return response.data.data
    }).mutate,
  }
}

export const useClientBilling = () => {
  return useQuery(
    ['client-billing'],

    async (_key) => {
      const [err, response] = await api.getClientBilling()

      if (err) throw err

      return response.data
    }
  )
}

// NEXT...
///////////////////////////////////////////////////////////////////////////////////////////////////
