import React from 'react'
import { get } from 'lodash'
import { FormControl, FormLabel, FormErrorMessage, Textarea } from '@chakra-ui/react'

const FormikTextarea = ({ field, form, disabled, id, label, placeholder, textareaProps }) => {
  let isTouched = true === get(form.touched, field.name)
  let errorMessage = get(form.errors, field.name)
  let hasError = isTouched && !!errorMessage

  return (
    <FormControl isInvalid={hasError}>
      {label && (
        <FormLabel htmlFor={id || field.name} fontSize="sm">
          {label}
        </FormLabel>
      )}

      <Textarea
        value={field.value || ''}
        placeholder={placeholder}
        isDisabled={disabled}
        name={field.name}
        onBlur={field.onBlur}
        onChange={field.onChange}
        {...textareaProps}
      />
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  )
}

export default FormikTextarea
