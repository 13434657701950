import React, { useState, useCallback } from 'react'
import { useQuery } from 'react-query'
import { Formik, Form, Field, FormikProps } from 'formik'
import * as Yup from 'yup'
import { Box, Row, Column, Button, Heading, Spinner, ButtonGroup, FormikElements } from 'src/ui'
import { useCustomerBillingById } from 'src/queries'
import { BillingActivity } from 'src/billing/shared/billing-activity'
import { useBillingHandlers } from 'src/billing/admin/helpers'
import { Subscriptions } from 'src/billing/shared/subscriptions'
import { SubscriptionPreview } from 'src/billing/shared/subscription-row'
import * as api from 'src/api'

export function CustomerBilling({ customer }: { customer: Customer }) {
  const billingQuery = useCustomerBillingById({ customerId: customer.id })
  const [isEditing, setIsEditing] = useState(false)
  const startEditing = useCallback(() => setIsEditing(true), [])
  const stopEditing = useCallback(() => setIsEditing(false), [])
  const {
    isEnabling,
    enableBilling,
    // isStarting,
    createSubscription,
    isCanceling,
    cancelSubscription,
  } = useBillingHandlers({ customerId: customer.id })

  if (billingQuery.isLoading) return <Loading mt={10} />
  if (billingQuery.isError) return <Error message="Error loading billing information" />

  const { activity, subscriptions, availableSubscriptions } = billingQuery.data ?? {}
  const hasStartedBilling = !!customer.billing_status
  const hasActiveSubscription = !!subscriptions?.find((s) => !s.canceled_at)

  // const [statusBadgeColor, statusBadgeLabel] = statusInfo(customer.billing_status)
  // const badge = <Badge colorScheme={statusBadgeColor}>{statusBadgeLabel}</Badge>

  return (
    <>
      <Column>
        <Row mb={2} h="32px" justifyContent="space-between" alignItems="center">
          <Heading size="sm">Billing</Heading>

          {billingQuery.isSuccess && (
            <Box>
              {hasStartedBilling ? (
                <>
                  {!isEditing && !hasActiveSubscription && (
                    <Button size="xs" width={140} colorScheme="purple" onClick={startEditing}>
                      New Subscription
                    </Button>
                  )}
                </>
              ) : (
                <Button
                  size="xs"
                  width={140}
                  colorScheme="purple"
                  isLoading={isEnabling}
                  onClick={() => enableBilling()}
                >
                  Enable Billing
                </Button>
              )}
            </Box>
          )}
        </Row>

        {billingQuery.isLoading && (
          <Row justifyContent="center" my={4}>
            <Spinner speed="0.65s" size="lg" />
          </Row>
        )}

        {billingQuery.isSuccess && hasStartedBilling && (
          <>
            {!isEditing && (
              <Subscriptions
                {...{
                  subscriptions,
                  allowCancel: true,
                  isCanceling,
                  cancelSubscription,
                }}
              />
            )}

            {isEditing && (
              <BillingForm
                {...{ customer, availableSubscriptions, createSubscription, stopEditing }}
              />
            )}

            <BillingActivity items={activity} />
          </>
        )}
      </Column>
    </>
  )
}

///////////////////////////////////////////////////////////////////////////////=

interface FormValues {
  service_variant_id: string
}

const FormSchema = Yup.object().shape({
  service_variant_id: Yup.string().required('Required'),
})

interface BillingFormProps {
  customer: Customer
  availableSubscriptions: any[]
  createSubscription: (args: { serviceVariantId: string; onSuccess: () => void }) => void
  stopEditing: () => void
}

export function BillingForm({
  customer,
  availableSubscriptions,
  createSubscription,
  stopEditing,
}: BillingFormProps) {
  const onSubmit = useCallback(
    async (values: FormValues, formikActions: FormikProps<FormValues>) => {
      createSubscription({
        serviceVariantId: values.service_variant_id,
        onSuccess: () => {
          stopEditing()
          formikActions.setSubmitting(false)
        },
      })
    },
    [createSubscription, stopEditing]
  )

  return (
    <Formik
      initialValues={{ service_variant_id: '' }}
      validationSchema={FormSchema}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {(formikBag: FormikProps<FormValues>) => (
        <FormBody {...{ customer, availableSubscriptions, formikBag, stopEditing }} />
      )}
    </Formik>
  )
}

///////////////////////////////////////////////////////////////////////////////

interface FormBodyProps {
  customer: Customer
  availableSubscriptions: any[]
  formikBag: FormikProps<FormValues>
  stopEditing: () => void
}

function FormBody({ customer, availableSubscriptions, formikBag, stopEditing }: FormBodyProps) {
  const customerId = customer.id
  const selectedVariant = availableSubscriptions.find(
    (sub) => sub.variant_id === formikBag.values.service_variant_id
  )
  const serviceId = selectedVariant?.service_id
  const serviceVariantId = selectedVariant?.variant_id

  const billingInfoQuery = useQuery(
    ['billing-info', { customerId, serviceId, serviceVariantId }],

    async (_key) => {
      const [err, response] = await api.getCustomerBillingInfoById({
        customerId,
        serviceId,
        serviceVariantId,
      })

      if (err) throw err
      return response.data
    },
    { enabled: serviceId && serviceVariantId }
  )

  return (
    <Form>
      <Column>
        <Row mb={4}>
          <Box flex={1.5}>
            <Field
              name="service_variant_id"
              id="service_variant_id"
              label="Service Subscription Plan"
              component={FormikElements.Select}
              selectProps={{
                width: '100%',
                maxWidth: 150,
                isClearable: false,
                isSorted: false,
              }}
              options={
                availableSubscriptions?.map((sub) => ({
                  value: sub.variant_id,
                  label: `${sub.service_name} - ${sub.variant_name}`,
                })) ?? []
              }
            />
          </Box>
        </Row>

        {billingInfoQuery.isLoading && <Loading my={4} />}
        {billingInfoQuery.isSuccess && (
          <Box mb={4}>
            <SubscriptionPreview
              customer={customer}
              serviceVariant={billingInfoQuery.data?.serviceVariant}
              breakdown={billingInfoQuery.data?.breakdown}
            />
          </Box>
        )}

        <Row justifyContent="flex-end">
          <ButtonGroup spacing={2}>
            <Button
              size="sm"
              variant="ghost"
              onClick={() => {
                formikBag.resetForm()
                stopEditing()
              }}
            >
              Cancel
            </Button>

            <Button
              size="sm"
              colorScheme="purple"
              isLoading={formikBag.isSubmitting}
              onClick={formikBag.submitForm}
            >
              Start Subscription
            </Button>
          </ButtonGroup>
        </Row>
      </Column>
    </Form>
  )
}

///////////////////////////////////////////////////////////////////////////////

function Loading(props) {
  return (
    <Row width="100%" justifyContent="center" {...props}>
      <Spinner speed="0.65s" size="lg" />
    </Row>
  )
}

function Error({ message = 'There was an error loading your profile.' }) {
  return (
    <Row width="100%" mt={10} justifyContent="center">
      {message}
    </Row>
  )
}
