import React from 'react'
import { Button, ButtonGroup } from '@chakra-ui/react'
import moment, { Moment } from 'moment'
import { FiArrowRight, FiX } from 'react-icons/fi'
import Row from 'src/ui/row'
import DatePicker from 'src/ui/date-picker'
// import config from 'utils/config'

const DateInput = ({ attr, value, onChange }) => {
  return (
    <Row width="165px" alignItems="center">
      <DatePicker
        id={`ranger-${attr}`}
        name="ranger"
        value={value.toDate()}
        showTimeSelect={false}
        dateFormat="MMMM d, yyyy"
        inputProps={{ size: 'sm' }}
        onChange={(day) => {
          onChange({ key: attr, date: moment(day) })
        }}
      />
    </Row>
  )
}

interface useRangerArgs {
  initialMonths: number
  initialStartDate: Moment
  initialEndDate: Moment
  defaultMonths?: number
  configBaseKey?: string
}

export const useRanger = ({
  initialMonths,
  initialStartDate,
  initialEndDate,
  defaultMonths = 3,
}: useRangerArgs) => {
  let [state, setState] = React.useState(() => ({
    months: initialMonths,
    startDate: initialStartDate,
    endDate: initialEndDate,
  }))

  return {
    months: state.months,
    startDate: state.startDate,
    endDate: state.endDate,

    defaultMonths,

    initialMonths,
    initialStartDate,
    initialEndDate,

    selectPreset: (months: number) => {
      let now = moment()
      let nextEndDate = now.clone().endOf('month')
      let startOfCurrentMonth = now.clone().startOf('month')
      let nextStartDate =
        months === 1
          ? startOfCurrentMonth.clone()
          : startOfCurrentMonth.clone().subtract(months - 1, 'months')

      // config.set(`${this.props.configBaseKey}.ranger`, { months, start_date, end_date })

      setState({ months, startDate: nextStartDate, endDate: nextEndDate })
    },

    selectCustom: () => setState((state) => ({ ...state, months: -1 })),

    setDate: ({ key, date }: { key: string; date: Moment }) =>
      setState((state) => ({ ...state, [key]: date })),
  }
}

export const RangerControls = ({
  defaultMonths,
  months,
  startDate,
  endDate,
  selectCustom,
  selectPreset,
  setDate,
}: ReturnType<typeof useRanger>) => {
  return (
    <Row>
      {months > 0 && (
        <ButtonGroup
          spacing={0}
          css={{
            button: {
              width: 50,
              borderRadius: 0,
            },
            'button:not(:last-of-type)': {
              borderRight: 'none',
            },
            'button:first-of-type': {
              borderTopLeftRadius: '0.25rem',
              borderBottomLeftRadius: '0.25rem',
            },
            'button:last-of-type': {
              borderTopRightRadius: '0.25rem',
              borderBottomRightRadius: '0.25rem',
            },
          }}
        >
          <Button
            size="sm"
            variant={months !== 1 ? 'outline' : undefined}
            onClick={() => selectPreset(1)}
          >
            1m
          </Button>
          <Button
            size="sm"
            variant={months !== 3 ? 'outline' : undefined}
            onClick={() => selectPreset(3)}
          >
            3m
          </Button>
          <Button
            size="sm"
            variant={months !== 6 ? 'outline' : undefined}
            onClick={() => selectPreset(6)}
          >
            6m
          </Button>
          <Button
            size="sm"
            variant={months !== 12 ? 'outline' : undefined}
            onClick={() => selectPreset(12)}
          >
            12m
          </Button>
          <Button
            size="sm"
            variant={months !== 24 ? 'outline' : undefined}
            onClick={() => selectPreset(24)}
          >
            24m
          </Button>
          <Button size="sm" variant="outline" onClick={selectCustom}>
            cust
          </Button>
        </ButtonGroup>
      )}

      {months === -1 && (
        <React.Fragment>
          <DateInput attr="startDate" value={startDate} onChange={setDate} />

          <Row justifyContent="center" alignItems="center" px={2}>
            <FiArrowRight />
          </Row>

          <DateInput attr="endDate" value={endDate} onChange={setDate} />

          <Button size="sm" variant="ghost" onClick={() => selectPreset(defaultMonths)} ml={2}>
            <FiX />
          </Button>
        </React.Fragment>
      )}
    </Row>
  )
}
