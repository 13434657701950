import React from 'react'
import { css } from '@emotion/core'
import { get } from 'lodash'
import PhoneInput from 'react-phone-input-2'
import { FormControl, FormLabel, FormErrorMessage } from '@chakra-ui/react'

const PhoneNumber = ({
  field,
  form,
  id,
  label,
  onBlur,
  onChange,
  // disabled,
  // placeholder,
  // type = 'text',
  // inputRef,
}) => {
  let isTouched = true === get(form.touched, field.name)
  let errorMessage = get(form.errors, field.name)
  let hasError = isTouched && !!errorMessage
  let value = field.value || ''

  return (
    <FormControl
      isInvalid={hasError}
      css={css`
        .react-tel-input {
          .form-control {
            width: 100%;
          }

          .flag-dropdown {
            display: none;
          }

          input {
            height: 2rem;
            padding-left: 8px;
            padding-right: 8px;
          }
        }
      `}
    >
      {label && (
        <FormLabel htmlFor={id || field.name} fontSize="sm">
          {label}
        </FormLabel>
      )}

      <PhoneInput
        jumpCursorToEnd
        disableDropdown // no search
        disableCountryCode // disable `1` prefix
        prefix="" // disable `+` prefix
        placeholder="(702) 555-1234"
        country="us"
        onlyCountries={['us']}
        inputProps={{ name: field.name }}
        value={value}
        onBlur={(e) => {
          if (onBlur) onBlur(e)
          field.onBlur(e)
        }}
        onChange={(phone: string) => {
          // allow custom onChange as well
          if (onChange) onChange(phone)

          form.setFieldValue(field.name, phone)
        }}
      />
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  )
}

export default PhoneNumber
