import React from 'react'
import { RouteComponentProps } from '@reach/router'
import VendorList from 'src/manage/vendors/list'
import VendorForm from 'src/manage/vendors/form'
import { DeleteDialog } from 'src/manage/dialogs'
import { useVendors, useVendorMutations } from 'src/queries'
import { useMutationHandlers } from 'src/utils/queries'
import { useDisclosure } from 'src/ui'

const Vendors = (_props: RouteComponentProps) => {
  let [objectToEdit, setObjectToEdit] = React.useState<Vendor>()
  let { isOpen, onOpen, onClose } = useDisclosure()
  let { deleteDialog, setDeleteDialog, resetDeleteDialog } = useDeleteDialog()
  let vendorsQuery = useVendors()
  let { createVendor, updateVendor, deleteVendor } = useVendorMutations()
  let { onSuccess, onError } = useMutationHandlers({
    invalidateQueries: ['vendors'],
    successMessage: 'Vendor Deleted',
    errorMessage: 'Failed to delete vendor',
  })

  return (
    <>
      <VendorList
        vendors={vendorsQuery.data || []}
        isLoading={vendorsQuery.status === 'loading'}
        startCreate={onOpen}
        startEdit={(user) => {
          setObjectToEdit(user)
          onOpen()
        }}
        startDelete={(obj) => setDeleteDialog((state) => ({ ...state, obj }))}
      />

      <VendorForm
        isOpen={isOpen}
        obj={objectToEdit}
        saveObject={objectToEdit ? updateVendor : createVendor}
        onDismiss={() => {
          setObjectToEdit(null)
          onClose()
        }}
      />

      <DeleteDialog
        isOpen={!!deleteDialog.obj}
        isLoading={deleteDialog.fetching}
        title="Delete Vendor"
        body={
          <>Are you sure you want to delete "{deleteDialog.obj ? deleteDialog.obj.name : ''}"?</>
        }
        onDismiss={resetDeleteDialog}
        onConfirm={async () => {
          setDeleteDialog((state) => ({ ...state, fetching: true }))

          deleteVendor(
            { id: deleteDialog.obj.id },
            {
              onSuccess: () => {
                onSuccess()
                resetDeleteDialog()
              },
              onError,
            }
          )
        }}
      />
    </>
  )
}

export default Vendors

///////////////////////////////////////////////////////////////////////////////////////////////////

export const useDeleteDialog = () => {
  let mountedRef = React.useRef(true)

  let dialogInitial = { fetching: false, obj: null }
  let [deleteDialog, setDeleteDialog] = React.useState(dialogInitial)

  React.useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  return {
    deleteDialog,
    setDeleteDialog,
    resetDeleteDialog: () => {
      if (!mountedRef.current) return
      setDeleteDialog(dialogInitial)
    },
  }
}
