import React, { memo, useCallback, useRef } from 'react'
import { RouteComponentProps } from '@reach/router'
import useComponentSize from '@rehooks/component-size'
import { Box, Column, Spinner } from 'src/ui'
import { FlexRouter } from 'src/routing'
import { useCalendarView } from 'src/store/calendar'
import * as utils from 'src/utils'
import { useSettings } from 'src/utils/settings'
import { useSchedule, useUrlSync } from 'src/schedule/hooks'
import ScheduleToolbar from 'src/schedule/schedule-toolbar'
import ScheduleMobile from 'src/schedule/schedule-mobile'
import ScheduleDesktop from 'src/schedule/schedule-desktop'
import TaskDrawer from 'src/deliveries/task-drawer'

export default function Schedule() {
  const containerRef = useRef(null)
  const { width } = useComponentSize(containerRef)
  const isMobile = width !== 0 && width < 600

  return (
    <Column height="100%" flex={1} ref={containerRef}>
      <FlexRouter styles={{ height: '100%' }}>
        <SchedulePage path="view/:routeId" isMobile={isMobile} />
        <SchedulePage path=":routeView/:routeDate" isMobile={isMobile} />
        <SchedulePage path=":routeView" isMobile={isMobile} />
        <SchedulePage default isMobile={isMobile} />
      </FlexRouter>
    </Column>
  )
}

///////////////////////////////////////////////////////////////////////////////////////////////////

const DEFAULT_STATUS_FILTERS = ['scheduled', 'in-progress', 'completed', 'canceled']
const STATUS_FILTER_KEY = 'deliveries.calendar.status_filter'

type CalendarView = 'week' | '3day' | 'day' | 'list'

interface SchedulePageProps extends RouteComponentProps {
  routeId?: string
  routeView?: CalendarView
  routeDate?: string
  isMobile?: boolean
}

const SchedulePage = memo(function ({
  routeId,
  routeView,
  routeDate,
  isMobile,
}: SchedulePageProps) {
  const { get: getSetting, set: setSetting } = useSettings()
  const statusFilters = getSetting(STATUS_FILTER_KEY, DEFAULT_STATUS_FILTERS) ?? []
  const setStatusFilters = useCallback((val) => setSetting(STATUS_FILTER_KEY, val), [setSetting])
  const { view, setView, markerDate } = useCalendarView()
  const {
    //
    anyLoading,
    anyFailed,
    allLoaded,
    tasks,
    resources,
    events,
  } = useSchedule({ statusFilters, isMobile, setView })

  useUrlSync({ view, markerDate, routeId })

  return (
    <>
      <ScheduleToolbar {...{ isMobile, statusFilters, setStatusFilters }} />
      {anyLoading && <PageLoading />}
      {anyFailed && <Box>There was a problem loading data</Box>}
      {allLoaded && <Calendar {...{ isMobile, events, view, markerDate, resources, tasks }} />}
    </>
  )
})

///////////////////////////////////////////////////////////////////////////////////////////////////

const Calendar = memo(function ({ isMobile, events, view, markerDate, resources, tasks }: any) {
  return (
    <>
      {isMobile ? (
        <ScheduleMobile
          events={events.filter((e) =>
            utils.momentLocalizedFromUtcString(e.starts_at).isSame(markerDate, 'date')
          )}
        />
      ) : (
        <ScheduleDesktop
          date={utils.momentLocalizedToDate(markerDate)}
          {...{ view, events, resources }}
        />
      )}

      <TaskDrawer tasks={tasks} />
    </>
  )
})

function PageLoading() {
  return (
    <Box
      flex={1}
      width="100%"
      mb={12}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      borderRadius={4}
      bg="rgba(0,0,0,0.25)"
      color="#fff"
    >
      <Box>
        <Spinner speed="0.65s" size="xl" />
      </Box>
    </Box>
  )
}
