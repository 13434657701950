import React from 'react'
import ReactSelect from 'react-select'
import { useTheme } from '@chakra-ui/react'

export type SelectOption = {
  value: string | number
  label: string
}

interface SelectProps {
  id?: string
  name?: string
  value?: string | number
  options: SelectOption[]
  placeholder?: string
  hasError?: boolean
  isDisabled?: boolean
  isLoading?: boolean
  isClearable?: boolean
  menuIsOpen?: boolean
  isSearchable?: boolean
  isSorted?: boolean
  width?: number
  onChange: any
  onBlur?: any
}

const Select = ({
  id,
  name,
  value,
  options,
  placeholder = 'Select...',
  hasError = false,
  isDisabled = false,
  isLoading = false,
  isClearable = true,
  isSearchable = true,
  isSorted = true,
  width = 200,
  onChange,
  onBlur,
  ...rest
}: SelectProps) => {
  let theme = useTheme()
  let red = theme.colors['red']['500']

  return (
    // @ts-ignore
    <ReactSelect
      id={id}
      name={name}
      value={value ? options.find((opt) => opt.value === value) : null}
      options={isSorted ? sortOptions(options) : options}
      placeholder={placeholder}
      isDisabled={isDisabled}
      isLoading={isLoading}
      isClearable={isClearable}
      isSearchable={isSearchable}
      styles={{
        control: (base) => ({
          ...base,
          width,
          height: 32,
          minHeight: 32,
          borderColor: hasError ? red : base.borderColor,
          boxShadow: hasError ? `0 0 0 1px ${red}` : undefined,
          '&:hover': hasError && {
            borderColor: red,
          },
        }),

        clearIndicator: (base) => ({
          ...base,
          padding: '0 6px',
        }),

        dropdownIndicator: (base) => ({
          ...base,
          padding: '0 6px',
        }),

        option: (base) => ({
          ...base,
          padding: '6px 10px',
        }),
      }}
      onChange={onChange}
      onBlur={onBlur}
      {...rest}
    />
  )
}

export default Select

const sortOptions = (options: SelectOption[]) => {
  return [...options].sort(({ label: la }, { label: lb }) => {
    if (typeof la === 'string' && typeof lb === 'string') {
      return la.toLowerCase() < lb.toLowerCase() ? -1 : 1
    }

    return la < lb ? -1 : 1
  })
}
