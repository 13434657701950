import React from 'react'
import * as Routing from 'src/routing'
import { CustomerHome } from 'src/customer/home'
import { SessionCallback } from 'src/customer/auth/session-callback'
import { CustomerLogin, CustomerAuthenticate } from 'src/customer/login'

export default function CustomerIndex(_props) {
  return (
    <Routing.FlexRouter basepath="/customer" styles={{ height: '100%' }}>
      <Routing.PublicRoute path="/login" as={CustomerLogin} />
      <Routing.PublicRoute path="/authenticate" as={CustomerAuthenticate} />
      <Routing.CustomerRoute path="/session/complete" as={SessionCallback} />
      <Routing.CustomerRoute path="/" as={CustomerHome} />
      <Routing.NotFound default />
    </Routing.FlexRouter>
  )
}
