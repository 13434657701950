import create from 'zustand'
import moment from 'moment'
import * as utils from 'src/utils'

export type CalendarView = 'week' | '3day' | 'day' | 'list'
export type CalendarStatus = 'idle' | 'info' | 'edit'

export interface CalendarState {
  view: CalendarView
  status: CalendarStatus
  delivery: Delivery
  markerDate: moment.Moment
  setView: (v: CalendarView) => void
  setMarkerDate: (d: moment.Moment) => void
  setDelivery: (d: Delivery) => void
  setDeliveryToEdit: (d: Delivery) => void
  closeSidebar: () => void
  startEditing: () => void
  stopEditing: () => void
}

export const useCalendarStore = create<CalendarState>((set, get) => ({
  view: getUrlViewParam(),
  status: 'idle',
  markerDate: utils.momentLocalizedParse(getUrlDateParam()),
  delivery: null,

  setView: async (view: CalendarView) => {
    set({ view })
  },

  setMarkerDate: async (markerDate: moment.Moment) => {
    set({ markerDate })
  },

  setDelivery: async (delivery: Delivery) => {
    set({ status: 'info', delivery })
  },

  setDeliveryToEdit: async (delivery: Delivery) => {
    set({ status: 'edit', delivery })
  },

  closeSidebar: async () => {
    set({ status: 'idle', delivery: null })
  },

  startEditing: async () => {
    set({ status: 'edit' })
  },

  stopEditing: async () => {
    if (get().delivery?.id) {
      set({ status: 'info' })
    } else {
      set({ status: 'idle', delivery: null })
    }
  },
}))

export function useCalendarView() {
  return useCalendarStore(
    ({ view, markerDate, setView, setMarkerDate, setDeliveryToEdit, setDelivery }) => ({
      view,
      markerDate,
      setView,
      setMarkerDate,
      setDeliveryToEdit,
      setDelivery,
    })
  )
}

export function useCalendarDelivery() {
  return useCalendarStore(({ delivery, status, closeSidebar, startEditing, stopEditing }) => ({
    delivery,
    status,
    closeSidebar,
    startEditing,
    stopEditing,
  }))
}

function getUrlViewParam() {
  const result = window.location.pathname.match(/\/schedule\/(\w*)\//)
  if (!result) return 'day'

  const [, view] = result
  return (view || 'day') as CalendarView
}

function getUrlDateParam() {
  const result = window.location.pathname.match(/\/schedule\/(\w*)\/(.*)$/)
  if (!result) return undefined

  const [, , markerDate] = result
  return markerDate || undefined
}
