import React, { useCallback, useMemo } from 'react'
import { Box, Badge, Heading, Table, useTable, Button, Row } from 'src/ui'
import { caseInsensitiveSort } from 'src/ui/table/helpers'
import * as utils from 'src/utils'
import { formatDate, formatTime } from 'src/deliveries/utils'
import { useSettings } from 'src/utils/settings'
import TaskDrawer from 'src/deliveries/task-drawer'
import { useCalendarView } from 'src/store/calendar'
import { createNewObject } from 'src/schedule/calendars/utils'
import moment from 'moment'

interface TaskListProps {
  isLoading: boolean
  tasks: TaskWithService[]
  selectedVendors: string[]
}

export default function TaskList({ isLoading, tasks = [], selectedVendors }: TaskListProps) {
  const { setDelivery, setDeliveryToEdit } = useCalendarView()
  const columns = useColumns({ setDelivery })

  return (
    <>
      <Row justifyContent="space-between" alignItems="center">
        <Heading size="md" color="gray.600">
          Tasks
        </Heading>

        <Box>
          <Button
            size="sm"
            colorScheme="purple"
            onClick={() =>
              setDeliveryToEdit(
                createNewObject({
                  start: moment().hour(12).minute(0).second(0),
                  end: moment().hour(14).minute(0).second(0),
                })
              )
            }
          >
            Schedule New Task
          </Button>
        </Box>
      </Row>

      <DeliveryTable
        columns={columns}
        isLoading={isLoading}
        data={tasks}
        selectedVendors={selectedVendors}
        // maxHeight={maxHeight - 148}
        maxHeight="500px"
      />

      <TaskDrawer tasks={tasks} />
    </>
  )
}

///////////////////////////////////////////////////////////////////////////////////////////////////

const nullAwareAccessor = (key) => (row) => row[key] || undefined
const filterVendors = (rows: any[], _id: string, filterValue: string[]) =>
  rows.filter((row) => filterValue.includes(row.original.vendor_id))

const DeliveryTable = ({ columns, isLoading, data, selectedVendors, maxHeight }) => {
  const { get: getSetting, set: setSetting } = useSettings()
  const hiddenColumns = getSetting('deliveries.delivery_table.hidden', [])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    // rows,
    page,
    pagination,
    // setFilter,
    setHiddenColumns,
    totalCount,
  } = useTable({
    columns,
    data,
    tableOptions: {
      initialState: {
        sortBy: [{ id: 'date', desc: true }],
        hiddenColumns,
      },
      autoResetFilters: false,
    },
  })

  const _setHiddenColumns = useCallback(
    (columnIds: string[]) => {
      setHiddenColumns(columnIds)
      setSetting('deliveries.delivery_table.hidden', columnIds)
    },
    [setHiddenColumns, setSetting]
  )

  // useEffect(() => {
  //   setFilter('vendor_name', selectedVendors.length > 0 ? selectedVendors : undefined)
  // }, [selectedVendors, setFilter])

  return (
    <Box>
      <Table.ScrollableWrapper maxHeight={maxHeight}>
        <Table {...getTableProps()}>
          <Table.Header headerGroups={headerGroups} />
          <Table.Body
            isLoading={isLoading}
            rows={page}
            getTableBodyProps={getTableBodyProps}
            prepareRow={prepareRow}
            // onRowClick={(obj) => startEdit(obj)}
          />
        </Table>
      </Table.ScrollableWrapper>

      {!isLoading && (
        <Table.Pagination
          totalCount={totalCount}
          allowSelectPageSize
          {...pagination}
          showColumnSelection
          columns={columns}
          hiddenColumns={hiddenColumns}
          setHiddenColumns={_setHiddenColumns}
        />
      )}
    </Box>
  )
}

function useColumns({ setDelivery }) {
  return useMemo(() => {
    return [
      {
        id: 'id',
        Header: 'ID',
        accessor: 'id',
      },
      {
        id: 'vendor_id_starts_at',
        Header: 'Vendor Key',
        accessor: 'vendor_id',
        Cell: ({ row: { original: data } }) => {
          return `${data.vendor_id}#${data.starts_at}`
        },
      },
      {
        id: 'date',
        Header: 'Date',
        accessor: 'starts_at',
        Cell: ({ row: { original: data } }) => {
          return (
            <>
              <Box as="span" mr={4}>
                {formatDate(data.starts_at)}
              </Box>
              <Box as="span">
                {formatTime(data.starts_at)}
                {' - '}
                {formatTime(data.ends_at)}
              </Box>
            </>
          )
        },
      },
      {
        id: 'service_name',
        Header: 'Service',
        accessor: (data) => {
          return data.service?.name
        },
        sortType: caseInsensitiveSort,
      },
      {
        id: 'vendor_name',
        Header: 'Vendor',
        accessor: nullAwareAccessor('vendor_name'),
        filter: filterVendors,
        sortType: caseInsensitiveSort,
      },
      {
        id: 'customer_name',
        Header: 'Customer',
        accessor: nullAwareAccessor('customer_name'),
      },
      {
        id: 'payment_method',
        Header: 'Payment',
        accessor: 'payment_method',
        sortType: caseInsensitiveSort,
      },
      {
        id: 'price',
        Header: 'Price',
        accessor: 'price',
        Cell: ({ row: { original: data } }) => {
          return (
            <>
              {!!data.price && (
                <Box as="span" mr={2}>
                  {utils.formatMoney(data.price)}
                </Box>
              )}

              {data.service?.task_attrs?.map((attr) => {
                if (!data[attr.name]) return null

                return (
                  <Badge key={attr.name} mr={2} colorScheme={attr.color}>
                    {attr.label}
                  </Badge>
                )
              })}
            </>
          )
        },
      },
      {
        id: 'actions',
        Header: '',
        Cell: ({ row: { original: data } }) => (
          <Button size="xs" onClick={() => setDelivery(data)}>
            View Details
          </Button>
        ),
      },
    ]
  }, [setDelivery])
}
