import React from 'react'
import { ConfirmDialog } from 'src/ui'

interface DeleteDialogProps {
  isOpen: boolean
  isLoading: boolean
  title: string | React.ReactNode
  body: string | React.ReactNode
  onConfirm: () => void
  onDismiss: () => void
}

export const DeleteDialog = ({
  isOpen,
  isLoading,
  title,
  body,
  onDismiss,
  onConfirm,
}: DeleteDialogProps) => {
  return (
    <ConfirmDialog
      title={title}
      body={body}
      isOpen={isOpen}
      isLoading={isLoading}
      onClose={onDismiss}
      onConfirm={onConfirm}
    />
  )
}
