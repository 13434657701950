import React, { useMemo } from 'react'
import { chakra } from '@chakra-ui/react'
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'
import { css } from '@emotion/core'
import { Box } from 'src/ui'
import {
  useTable as useReactTable,
  useSortBy,
  useFilters,
  useGlobalFilter,
  usePagination,
} from 'react-table'
import { Row, Icon, Spinner, useToken } from 'src/ui'
import Pagination from 'src/ui/table/pagination'

const Table = ({ styles = null, children }) => {
  const [gray500, gray100] = useToken('colors', ['gray.500', 'gray.100'])

  return (
    <chakra.table
      boxShadow="sm"
      css={css`
        width: 100%;
        border-spacing: 0;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        background-color: #fff;

        thead tr th:first-of-type {
          border-top-left-radius: 3px;
        }

        thead tr th:last-of-type {
          border-top-right-radius: 3px;
        }

        tbody tr:last-of-type td:first-of-type {
          border-bottom-left-radius: 3px;
        }

        tbody tr:last-of-type td:last-of-type {
          border-bottom-right-radius: 3px;
        }

        thead tr th {
          font-size: 0.9em;
          color: ${gray500};
          background-color: ${gray100};
          text-transform: uppercase;
          text-align: left;
        }

        th {
          height: 40px;
          padding: 0 1.5rem;
        }

        td {
          height: 40px;
          padding: 0 1.5rem;
        }

        tr {
          :last-child {
            td {
              border-bottom: 0;
            }
          }
        }

        td {
          border-bottom: 1px solid ${gray100};
        }

        td.loading-indicator {
          width: 100%;
          height: 4rem;
          text-align: center;
          background: #fff;
          border-bottom-left-radius: 6px;
          border-bottom-right-radius: 6px;
        }

        ${styles}
      `}
    >
      {children}
    </chakra.table>
  )
}

///////////////////////////////////////////////////////////////////////////////

const ScrollableWrapper = ({ maxHeight, children }) => {
  return (
    <chakra.div
      boxShadow="md"
      css={css`
        overflow: auto;
        max-height: ${maxHeight};

        table {
          border-collapse: collapse;
        }

        thead tr th {
          position: sticky;
          top: 0;
        }
      `}
    >
      {children}
    </chakra.div>
  )
}

///////////////////////////////////////////////////////////////////////////////

const Header = ({ headerGroups }) => (
  <thead>
    {headerGroups.map((headerGroup) => (
      <tr {...headerGroup.getHeaderGroupProps()}>
        {headerGroup.headers.map((column) => (
          <th {...column.getHeaderProps(column.getSortByToggleProps())}>
            {column.render('Header')}

            <Box as="span" position="absolute" ml="8px" mt="-2px">
              {column.isSorted ? (
                column.isSortedDesc ? (
                  <Icon as={ChevronDownIcon} size="1rem" />
                ) : (
                  <Icon as={ChevronUpIcon} size="1rem" />
                )
              ) : (
                ''
              )}
            </Box>
          </th>
        ))}
      </tr>
    ))}
  </thead>
)

///////////////////////////////////////////////////////////////////////////////

const Body = ({ isLoading, rows, getTableBodyProps, prepareRow, onRowClick = null }) => {
  if (isLoading) {
    return (
      <tbody>
        <tr>
          <td colSpan={100} className="loading-indicator">
            <Spinner speed="0.65s" size="md" />
          </td>
        </tr>
      </tbody>
    )
  }

  if (rows.length === 0) {
    return (
      <tbody {...getTableBodyProps()}>
        <tr>
          <td colSpan={100}>
            <Row
              alignItems="center"
              justifyContent="center"
              width="100%"
              height="4rem"
              color="gray.500"
              fontWeight={500}
            >
              No Data
            </Row>
          </td>
        </tr>
      </tbody>
    )
  }

  return (
    <tbody {...getTableBodyProps()}>
      {rows.map((row, index) => {
        prepareRow(row)

        return (
          <tr
            {...row.getRowProps()}
            onClick={onRowClick ? () => onRowClick(row.original) : undefined}
            css={
              onRowClick
                ? {
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: '#F7FAFC',
                    },
                  }
                : {
                    // borderTop: index === 2 ? '2px solid purple' : undefined,
                  }
            }
          >
            {row.cells.map((cell) => {
              return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
            })}
          </tr>
        )
      })}
    </tbody>
  )
}

///////////////////////////////////////////////////////////////////////////////

Table.ScrollableWrapper = ScrollableWrapper
Table.Header = Header
Table.Body = Body
Table.Pagination = Pagination

export default Table

///////////////////////////////////////////////////////////////////////////////

export const useTable = ({ data, columns, tableOptions }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setFilter,
    setGlobalFilter,
    preGlobalFilteredRows,
    setHiddenColumns,
    state: { pageIndex, pageSize },
  } = useReactTable(
    {
      columns,
      data,
      ...tableOptions,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  return {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows, // all rows
    page, // current page of rows
    setFilter,

    setHiddenColumns,
    globalFilter: useMemo(
      () => ({
        preGlobalFilteredRows,
        setGlobalFilter,
        isFiltered: rows.length < preGlobalFilteredRows.length,
        preFilterCount: preGlobalFilteredRows.length,
      }),
      [rows, preGlobalFilteredRows, setGlobalFilter]
    ),
    pagination: useMemo(
      () => ({
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        pageIndex,
        pageSize,
      }),
      [
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        pageIndex,
        pageSize,
      ]
    ),
    // mine
    totalCount: rows.length,
  }
}
