import React from 'react'
import CreatableSelect from 'react-select/creatable'
import { useTheme } from '@chakra-ui/react'

type Option = {
  value: string | number
  label: string | number
}

interface SelectProps {
  id?: string
  name?: string
  value?: string | number
  options: Option[]
  placeholder?: string
  hasError?: boolean
  isDisabled?: boolean
  isLoading?: boolean
  isClearable?: boolean
  menuIsOpen?: boolean
  isSearchable?: boolean
  width?: number
  onChange: any
  onBlur?: any
}

const Select = ({
  id,
  name,
  value,
  options: initialOptions,
  placeholder = 'Select...',
  hasError = false,
  isDisabled = false,
  isLoading = false,
  isClearable = true,
  isSearchable = true,
  width = 200,
  onChange,
  onBlur,
  ...rest
}: SelectProps) => {
  let theme = useTheme()
  let [options, setOptions] = React.useState(initialOptions)

  React.useEffect(() => {
    let found = options.find((opt) => opt.value === value)
    if (found || !value) return
    setOptions((state) => [...state, { value, label: value }])
  }, [value, options])

  React.useEffect(() => {
    // Ensure `initialOptions` is memo'd
    setOptions(initialOptions)
  }, [initialOptions])

  const handleCreate = (value: string) => {
    let newItem = { value, label: value }

    setOptions((state) => [...state, newItem])
    onChange(newItem)
  }

  let red = theme.colors['red']['500']

  return (
    // @ts-ignore
    <CreatableSelect
      id={id}
      name={name}
      value={value ? options.find((opt) => opt.value === value) : null}
      options={sortOptions(options)}
      placeholder={placeholder}
      isDisabled={isDisabled}
      isLoading={isLoading}
      isClearable={isClearable}
      isSearchable={isSearchable}
      styles={{
        container: (base) => ({
          ...base,
          width,
        }),

        control: (base) => ({
          ...base,
          width,
          height: 32,
          minHeight: 32,
          borderColor: hasError ? red : base.borderColor,
          boxShadow: hasError ? `0 0 0 1px ${red}` : undefined,
          '&:hover': hasError && {
            borderColor: red,
          },
        }),

        clearIndicator: (base) => ({
          ...base,
          padding: '0 6px',
        }),

        dropdownIndicator: (base) => ({
          ...base,
          padding: '0 6px',
        }),

        option: (base) => ({
          ...base,
          padding: '6px 10px',
        }),

        menuList: (base) => ({
          ...base,
          width,
        }),

        noOptionsMessage: (base) => ({
          ...base,
          width,
          padding: '6px 10px',
        }),
      }}
      onCreateOption={handleCreate}
      onChange={onChange}
      onBlur={onBlur}
      {...rest}
    />
  )
}

export default Select

const sortOptions = (options: Option[]) => {
  return [...options].sort(({ label: la }, { label: lb }) => {
    if (typeof la === 'string' && typeof lb === 'string') {
      return la.toLowerCase() < lb.toLowerCase() ? -1 : 1
    }

    return la < lb ? -1 : 1
  })
}
