import React from 'react'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
} from 'src/ui'

interface ConfirmDialogProps {
  isOpen: boolean
  isLoading: boolean
  title: string | React.ReactNode
  body: string | React.ReactNode
  onClose: () => void
  onConfirm: () => void
}

const DialogConfirm = ({
  title,
  body,
  isOpen = false,
  isLoading = false,
  onClose,
  onConfirm,
}: ConfirmDialogProps) => {
  let cancelRef = React.useRef()

  return (
    <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
      <AlertDialogOverlay />

      <AlertDialogContent>
        <AlertDialogHeader fontSize="lg" fontWeight="bold">
          {title}
        </AlertDialogHeader>

        <AlertDialogBody>{body}</AlertDialogBody>

        <AlertDialogFooter>
          <Button size="sm" ref={cancelRef} mr={3} isDisabled={isLoading} onClick={onClose}>
            Cancel
          </Button>

          <Button size="sm" colorScheme="red" isLoading={isLoading} onClick={onConfirm}>
            Delete
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default DialogConfirm
