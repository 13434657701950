import moment from 'moment'

const INITIAL_MONTHS = 1

export const formatDate = (ts: string) => moment(ts).format('YYYY-MM-DD')
export const formatTime = (ts: string) => moment(ts).format('h:mm a')
// export const nullAwareAccessor = (key) => (row) => row[key] || undefined
// export const filterVendors = (rows: any[], _id: string, filterValue: string[]) =>
//   rows.filter((row) => filterValue.includes(row.original.vendor_id))

export const createRangerArgs = ({ queryArgs }) => {
  const initialMonths =
    queryArgs?.startDate || queryArgs?.endDate
      ? -1
      : queryArgs?.range
      ? parseInt(queryArgs?.range)
      : INITIAL_MONTHS

  return {
    defaultMonths: INITIAL_MONTHS,
    initialMonths,
    initialStartDate: queryArgs?.startDate
      ? moment(queryArgs?.startDate).utc()
      : moment().utc().subtract(initialMonths, 'months').startOf('month'),
    initialEndDate: queryArgs?.endDate
      ? moment(queryArgs?.endDate).utc()
      : moment().add(7, 'days').utc(),
  }
}

export function calcDeliveryStats(deliveries) {
  const pricedDeliveries = deliveries.filter((d) => !!d.price)
  const corporateDeliveries = deliveries.filter((d) => d.is_corporate)

  return {
    deliveryCount: deliveries.length,

    pricedCount: pricedDeliveries.length,
    pricedPercent: percentify(pricedDeliveries.length, deliveries.length),

    corporateCount: corporateDeliveries.length,
    corporatePercent: percentify(corporateDeliveries.length, deliveries.length),

    averagePrice:
      pricedDeliveries.length === 0
        ? 0
        : Math.round(
            pricedDeliveries.reduce((acc, d) => acc + d.price, 0) / pricedDeliveries.length
          ),
  }
}

function percentify(numerator: number, denominator: number) {
  return denominator === 0 ? 0 : Math.round((numerator / denominator) * 100)
}

export function isTaskSubscription(delivery: Delivery) {
  return delivery.service_variant_id?.indexOf('subscription#') === 0
}

export function getTaskBillingFacts(delivery: Delivery) {
  const usesSubscriptionBilling = delivery.service_variant_id?.indexOf('subscription#') === 0
  const usesOnlineBilling = delivery.payment_method === 'stripe'
  const hasFrozenBilling = delivery.id && (usesSubscriptionBilling || usesOnlineBilling)

  return { usesSubscriptionBilling, usesOnlineBilling, hasFrozenBilling }
}
