import { useState, useEffect, useMemo } from 'react'
import { Moment } from 'moment'
import { replace } from 'src/routing'
import {
  useBatchBootstrap,
  useVendors,
  useCustomers,
  useDriverSchedules,
  useTasks,
} from 'src/queries'
import { useAuthState } from 'src/store/auth'
import { useCalendarView } from 'src/store/calendar'
import { mapEvents, mapResources } from 'src/schedule/calendars/utils'
import * as utils from 'src/utils'
import * as queryUtils from 'src/utils/queries'

const createDateRange = (marker: Moment) => ({
  startDate: marker.clone().utc().subtract(1, 'month').format(),
  endDate: marker.clone().utc().add(1, 'month').format(),
})

///////////////////////////////////////////////////////////////////////////////////////////////////

export const useSchedule = ({
  statusFilters,
  isMobile,
  setView,
}: {
  statusFilters: string[]
  isMobile: boolean
  setView: any
}) => {
  const { isAdmin, user } = useAuthState()
  const { view, markerDate } = useCalendarView()
  const [{ startDate, endDate }, setQueryDates] = useState(() => createDateRange(markerDate))
  const { batchQuery, drivers, services } = useCommons()
  const driverSchedulesQuery = useDriverSchedules({ range_start: startDate, range_end: endDate })
  const taskQuery = useTasks({ range_start: startDate, range_end: endDate })
  const allQueries = [batchQuery, driverSchedulesQuery, taskQuery]
  const anyLoading = queryUtils.areAnyLoading(...allQueries)
  const anyFailed = queryUtils.areAnyFailed(...allQueries)
  const allLoaded = queryUtils.areAllLoaded(...allQueries)
  const filterUserId = isAdmin ? false : user.id

  useCalendarDateChangeFetcher({ startDate, endDate, markerDate, setQueryDates })
  useCheckForceMobileView({ isMobile, view, setView })

  const { tasks, resources, events } = useMemo(() => {
    if (!allLoaded) return { tasks: [], resources: [], events: [] }

    const filteredTasks = (taskQuery.data || [])
      .filter((d) => !filterUserId || d.driver_id === filterUserId)
      .filter((d) => statusFilters.includes(d.status))

    return {
      services,
      tasks: filteredTasks,
      resources: mapResources(drivers, driverSchedulesQuery.data),
      events: mapEvents({ drivers, view, list: filteredTasks }),
    }
  }, [
    allLoaded,
    view,
    drivers,
    services,
    filterUserId,
    statusFilters,
    taskQuery.data,
    driverSchedulesQuery.data,
  ])

  return {
    anyLoading,
    anyFailed,
    allLoaded,
    tasks,
    resources,
    events,
  }
}

/**
 * Force "day" view on mobile
 */
function useCheckForceMobileView({ isMobile, view, setView }) {
  useEffect(() => {
    if (!isMobile || view === 'day') return
    setView('day')
  }, [isMobile, view, setView])
}

/**
 * Fetches new data if the currently-focused calendar date changes in a significant way.
 * "Significant way" = "Marker date gets to the first or last 7 days of the current range"
 */
function useCalendarDateChangeFetcher({ startDate, endDate, markerDate, setQueryDates }) {
  useEffect(() => {
    const start = utils.momentLocalizedParse(startDate)
    const end = utils.momentLocalizedParse(endDate)
    const isInsideRange = markerDate.isAfter(start) && markerDate.isBefore(end)

    let shouldUpdate = false

    if (isInsideRange) {
      // moved around inside the currently-loaded range
      const daysToStart = Math.abs(markerDate.diff(start, 'days'))
      const daysToEnd = Math.abs(markerDate.diff(end, 'days'))

      if (daysToStart < 7 || daysToEnd < 7) {
        // currently < 1wk away from currently-loaded edges, update
        shouldUpdate = true
      }
    } else {
      // jumped outside the currently-loaded range, update
      shouldUpdate = true
    }

    if (shouldUpdate) setQueryDates(createDateRange(markerDate))
  }, [markerDate, endDate, startDate, setQueryDates])
}

export function useCommons() {
  const batchQuery = useBatchBootstrap()

  return {
    batchQuery,

    tenant: batchQuery.data?.tenant,

    drivers: useMemo(
      () => (batchQuery.data?.users || []).filter((u) => u.role === 'driver'),
      [batchQuery.data]
    ),

    vehicles: useMemo(() => batchQuery.data?.vehicles || [], [batchQuery.data]),

    services: batchQuery.data?.services,
  }
}

export function useVendorsAndCustomers() {
  const vendorsQuery = useVendors()
  const customersQuery = useCustomers()

  return {
    vendorsQuery,
    vendors: useMemo(() => vendorsQuery.data || [], [vendorsQuery.data]),

    customersQuery,
    customers: useMemo(() => customersQuery.data || [], [customersQuery.data]),
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////

interface useUrlSyncArgs {
  view: string
  // markerDate: Moment
  markerDate: any
  routeId: string
}

export function useUrlSync({ view, markerDate, routeId }: useUrlSyncArgs) {
  useEffect(() => {
    if (routeId) return
    replace(`/schedule/${view}/${markerDate.format('YYYY-MM-DD')}`)
  }, [view, markerDate, routeId])
}
