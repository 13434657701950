import { css, Global } from '@emotion/core'
import { theme } from '@chakra-ui/react'

import 'react-datepicker/dist/react-datepicker.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import 'react-phone-input-2/lib/style.css'

const chakraTheme: any = theme

export const GlobalStyles = () => {
  return (
    <Global
      styles={css`
        /* Global App Styles */

        body {
          font-size: 14px;
          font-weight: 400;
          letter-spacing: 0;
          line-height: 1.28581;
          text-transform: none;
          background-color: ${chakraTheme.colors.gray['50']};
          color: ${chakraTheme.colors.gray['800']};
          font-family: -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
            'Cantarell', 'Open Sans', 'Helvetica Neue', 'Icons16', sans-serif;
        }

        a {
          color: inherit;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }

        div[data-reach-menu] {
          z-index: 999;
        }

        ${datePickerStyles}

        /* table */
        .rbc-calendar {
          background-color: #fff;
          padding: 16px;
          border-radius: 4px;
          box-shadow: ${theme.shadows.lg};
        }

        .rbc-toolbar {
          font-size: 14px;

          button {
            height: 30px;
            padding: 0 16px;
          }
        }

        .rbc-header {
          padding: 8px 0;
          font-size: 15px;
          border-bottom: none;

          a {
            text-decoration: none;
            cursor: inherit;
          }
        }

        .rbc-today {
          background-color: initial;
        }

        .rbc-current-time-indicator {
          /* background-color: #8574ea; */
          display: none;
        }

        .rbc-day-slot .rbc-event {
          /* background-color: red; */
          /* border: 1px solid red; */
        }

        .rbc-day-slot .rbc-events-container {
          margin-right: 0;
        }

        .rbc-event {
          padding: 0;
          border-radius: 0;
          box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
          font-size: 12px;
        }

        .rbc-event-content {
          position: relative;
          padding: 4px;
        }

        .rbc-time-view-resources .rbc-time-gutter,
        .rbc-time-view-resources .rbc-time-header-gutter {
          z-index: unset;
        }

        /* removes all-day cell */
        .rbc-allday-cell {
          display: none;
        }

        .rbc-day-slot .rbc-event-label {
          width: 100%;
          text-align: center;
          /* font-size:; */
          /* view === 'day' ? '13px' : '11px' */
          font-size: 12px;
          font-weight: 600;
          padding: 0 4px;
        }

        .rbc-timeslot-group {
          border-bottom: none;
        }

        .rbc-time-gutter {
          .rbc-timeslot-group {
            border-bottom: 1px solid #f7f7f7;

            .rbc-time-slot {
              background-color: #8f8f8f;
              display: flex;
              flex-direction: column;
              justify-content: center;
              font-size: 13px;
              color: #fff;

              &:nth-of-type(2) {
                display: none;
              }
            }
          }
        }
      `}
    />
  )
}

const datePickerStyles = css`
  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker {
    color: #595959;
    border: none;
    box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.25);
  }

  .react-datepicker__input-container {
    width: 100%;

    input {
      width: 100%;
    }
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__header {
    background-color: #fff;
    border-bottom: none;
  }

  .react-datepicker__current-month {
    padding-bottom: 10px;
    font-weight: 500;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__day--keyboard-selected {
    color: #fff;
    background-color: #8888fc;
    font-weight: 500;

    &:hover {
      background-color: #c4c6ff;
    }
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    color: #fff;
    background-color: #8888fc;
    font-weight: 500;

    &:hover {
      background-color: #c4c6ff;
    }
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header {
    color: inherit;
  }

  .react-datepicker__day-names {
    border-top: 1px solid #eee;
  }

  .react-datepicker__time-container {
    width: 86px;
    border-left: 1px solid #eee;
  }

  .react-datepicker__time-container .react-datepicker__time {
    background: transparent;
    overflow: hidden;
  }

  .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 95px;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item {
    height: auto;
    padding: 6px 4px;
  }

  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width: 86px;
    margin: 0;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    font-weight: 500;
  }

  .react-datepicker-popper {
    width: 330px;
  }
`
