import moment, { Moment } from 'moment-timezone'
import { parsePhoneNumberFromString } from 'libphonenumber-js'

// MONEY
///////////////////////////////////////////////////////////////////////////////

export const moneyFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })

export function formatMoney(value: number, fallback?: string) {
  return typeof value === 'undefined' && fallback ? fallback : moneyFormatter.format(value)
}

// PHONE NUMBERS
///////////////////////////////////////////////////////////////////////////////

export const prettyPhoneNumber = (phone) => {
  if (!phone || typeof phone !== 'string') return phone

  let parsed = parsePhoneNumberFromString(phone, 'US')
  return parsed ? parsed.formatNational() : phone
}

export const parsePhoneNumber = (phone: string) => {
  let parsed = parsePhoneNumberFromString(phone, 'US')
  return parsed || null
}

export const parseToNationalNumber = (phone: string): string => {
  if (!phone) return phone

  let parsed = parsePhoneNumber(phone)

  return parsed ? String(parsed.nationalNumber) : phone
}

export const e164PhoneNumber = (phone) => {
  if (!phone || typeof phone !== 'string') return phone

  let parsed = parsePhoneNumberFromString(phone, 'US')
  return parsed ? parsed.number : phone
}

// DATES
///////////////////////////////////////////////////////////////////////////////

// @todo: fixme
const TIMEZONE = 'America/Los_Angeles'

export const momentLocalizedParse = (dateString?: string) => moment.tz(dateString, TIMEZONE)
export const momentLocalizedFromUtcString = (dateString: string) => moment(dateString).tz(TIMEZONE)
export const momentLocalizedParseDate = (dateObject: Date) => {
  return moment()
    .tz(TIMEZONE)
    .year(dateObject.getFullYear())
    .month(dateObject.getMonth())
    .date(dateObject.getDate())
    .hour(dateObject.getHours())
    .minute(dateObject.getMinutes())
    .second(0)
}
export const momentUtcParseDate = (dateObject: Date) => {
  return moment()
    .utc()
    .year(dateObject.getFullYear())
    .month(dateObject.getMonth())
    .date(dateObject.getDate())
    .hour(dateObject.getHours())
    .minute(dateObject.getMinutes())
    .second(0)
}

export const momentLocalizedToDate = (mdate: Moment) => {
  return new Date(mdate.year(), mdate.month(), mdate.date(), mdate.hour(), mdate.minute(), 0)
}

export const utcStringToLocalDate = (dateString?: string) =>
  momentLocalizedToDate(momentLocalizedFromUtcString(dateString))

export const formatLocal = (utcDateString: string, format?: string) =>
  moment(utcDateString).tz(TIMEZONE).format(format)

// DEBUG
///////////////////////////////////////////////////////////////////////////////////////////////////
;(window as any).parsePhoneNumberFromString = parsePhoneNumberFromString
;(window as any).moment = moment
;(window as any).TIMEZONE = TIMEZONE
