import { Box, forwardRef } from '@chakra-ui/react'

const Row = forwardRef((props, ref) => <Box {...props} ref={ref} />)

Row.defaultProps = {
  display: 'flex',
}

Row.displayName = 'Row'

export default Row
