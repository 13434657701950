import React, { useState, useCallback } from 'react'
import { SunIcon } from '@chakra-ui/icons'
import { useQueryClient } from 'react-query'
import { Box, Row, Column, Button, ButtonGroup, Icon, Modal, Stack, useToast } from 'src/ui'
import { useAuthState } from 'src/store/auth'
import { useCalendarView } from 'src/store/calendar'
import { useDeliveryMutations } from 'src/queries'
import { useCommons } from 'src/schedule/hooks'
import { EventDayMobile } from 'src/schedule/calendars/parts'

interface MobileViewsProps {
  events: any[]
}

export default function ScheduleMobile({ events }: MobileViewsProps) {
  const toast = useToast()
  const [isStatusLoading, setStatusLoading] = useState(false)
  const [statusConfirmModal, setStatusConfirmModal] = useState<{
    id: string
    status: string
  }>(null)
  const { isAdmin } = useAuthState()
  const queryClient = useQueryClient()
  const { setDeliveryToEdit } = useCalendarView()
  const { updateDelivery } = useDeliveryMutations()
  const { drivers } = useCommons()

  const updateDeliveryAction = useCallback(
    async (args: Partial<Delivery>) => {
      setStatusLoading(true)

      updateDelivery(args, {
        onSuccess: async (_response, _args) => {
          setStatusConfirmModal(null)

          await queryClient.invalidateQueries('tasks')

          toast({
            title: 'Success',
            description: 'Delivery Updated',
            status: 'success',
            duration: 4000,
            isClosable: false,
            position: 'top',
          })
        },

        onError: async (...args) => {
          toast({
            title: 'Whoops',
            description: 'Unable to save delivery',
            status: 'error',
            duration: 4000,
            isClosable: false,
            position: 'top',
          })
        },

        onSettled: async (...args) => setStatusLoading(false),
      })
    },
    [updateDelivery, toast, queryClient]
  )

  if (events.length === 0) {
    return (
      <Row justifyContent="center" mt={5}>
        <Column alignItems="center">
          <Icon as={SunIcon} boxSize="2rem" color="gray.600" />

          <Box mt={4} fontSize="md" fontStyle="italic">
            No deliveries for this date
          </Box>
        </Column>
      </Row>
    )
  }

  return (
    <>
      <Stack overflow="auto" spacing={4} shouldWrapChildren px={2} pb={4}>
        {events.map((event) => (
          <EventDayMobile
            key={event.id}
            isAdmin={isAdmin}
            event={event}
            driver={drivers.find((d) => d.id === event.driver_id)}
            setEditDialog={setDeliveryToEdit}
            setStatusConfirmModal={setStatusConfirmModal}
            updateDelivery={updateDeliveryAction}
          />
        ))}
      </Stack>

      <Modal
        isOpen={!!statusConfirmModal}
        size="sm"
        title="Update Status"
        onClose={() => setStatusConfirmModal(null)}
        footer={
          <ButtonGroup spacing={2}>
            <Button
              width="80px"
              size="sm"
              variant="ghost"
              // ref={dialogRef}
              isDisabled={isStatusLoading}
              onClick={() => setStatusConfirmModal(null)}
            >
              Cancel
            </Button>

            <Button
              width="80px"
              size="sm"
              colorScheme="purple"
              isLoading={isStatusLoading}
              onClick={() => updateDeliveryAction(statusConfirmModal)}
            >
              Confirm
            </Button>
          </ButtonGroup>
        }
      >
        Update this delivery's status to <strong>{statusConfirmModal?.status}</strong>?
      </Modal>
    </>
  )
}
