import React from 'react'
import { FormLabel, Switch } from '@chakra-ui/react'

export function FormikSwitch({
  field,
  form,
  disabled,
  id,
  label,
  placeholder,
  type = 'text',
  inputRef,
  ...rest
}) {
  // let isTouched = true === get(form.touched, field.name)
  // let errorMessage = get(form.errors, field.name)
  // let hasError = isTouched && !!errorMessage

  return (
    <>
      <FormLabel htmlFor={id || field.name} fontSize="xs" fontWeight="normal">
        {label}
      </FormLabel>

      <Switch
        id={id || field.name}
        isChecked={!!field.value}
        isDisabled={disabled}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          form.setFieldValue(field.name, e.target.checked)
        }
      />
    </>
  )
}

export default FormikSwitch
