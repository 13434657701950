import React from 'react'
import { get } from 'lodash'
import { FormControl, FormLabel, FormErrorMessage, Input } from '@chakra-ui/react'

const FormikInput = ({
  field,
  form,
  disabled,
  id,
  label,
  onBlur,
  onChange,
  placeholder,
  type = 'text',
  inputRef,
  ...rest
}) => {
  let isTouched = true === get(form.touched, field.name)
  let errorMessage = get(form.errors, field.name)
  let hasError = isTouched && !!errorMessage

  return (
    <FormControl isInvalid={hasError}>
      {label && (
        <FormLabel htmlFor={id || field.name} fontSize="sm">
          {label}
        </FormLabel>
      )}

      <Input
        ref={inputRef}
        type={type}
        id={id || field.name}
        placeholder={placeholder}
        isDisabled={disabled}
        value={field.value || ''}
        name={field.name}
        onBlur={(e) => {
          if (onBlur) onBlur(e)
          field.onBlur(e)
        }}
        onChange={(e) => {
          // allow custom onChange as well
          if (onChange) onChange(e.target.value)

          field.onChange(e)
        }}
        {...rest}
      />
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  )
}

export default FormikInput
