export const LS_KEYS = {
  AUTH: 'falcon-auth',
  SETTINGS: 'falcon-settings',
}

export const getLocalStorageKey = (key: string, defaultValue: any = null) => {
  let stored = window.localStorage.getItem(key)

  if (stored === null) return defaultValue

  return JSON.parse(stored)
}

export const setLocalStorageKey = (key: string, value: any) => {
  window.localStorage.setItem(key, JSON.stringify(value))
}

export const removeLocalStorageKey = (key: string) => {
  window.localStorage.removeItem(key)
}
