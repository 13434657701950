import React from 'react'
import { Row, Column } from 'src/ui'

export function DetailItem({ label, value = '-' }) {
  return (
    <Column>
      <Row color="gray.500" mb={1}>
        {label}
      </Row>
      <Row>{value}</Row>
    </Column>
  )
}
