import React, { useCallback, useState, useEffect } from 'react'
import {
  Box,
  Row,
  Column,
  Button,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  Fade,
} from 'src/ui'
import * as utils from 'src/utils'
import Documents from 'src/deliveries/documents'
import { CustomerForm } from 'src/manage/customers/customer-form'
import { DetailItem } from 'src/billing/shared/detail-item'
import { CustomerBilling } from 'src/billing/admin'
import { useCustomer } from 'src/queries'

interface CustomerSidebarProps {
  sidebarId: string
  setSidebarId: (id: string) => void
  isOpen: boolean
  createCustomer: any
  updateCustomer: any
  onDismiss: () => void
}

export function CustomerSidebar({
  sidebarId,
  setSidebarId,
  isOpen,
  createCustomer,
  updateCustomer,
  onDismiss: _onDismiss,
}: CustomerSidebarProps) {
  const isEnabled = !!sidebarId && sidebarId !== 'new'
  const customerQuery = useCustomer({ id: sidebarId, enabled: isEnabled })
  const [isEditing, setIsEditing] = useState(sidebarId === 'new')
  const startEditing = useCallback(() => setIsEditing(true), [setIsEditing])
  const stopEditing = useCallback(() => setIsEditing(false), [setIsEditing])
  const onDismiss = useCallback(() => {
    _onDismiss()
    setIsEditing(false)
  }, [_onDismiss])

  useEffect(() => {
    if (sidebarId === 'new') setIsEditing(true)
  }, [sidebarId])

  return (
    <Sidebar
      isOpen={isOpen}
      onClose={onDismiss}
      header={<Header isNew={sidebarId === 'new'} />}
      footer={<Footer {...{ status: 'edit', onDismiss, startEditing, stopEditing }} />}
    >
      {(() => {
        if (customerQuery.isLoading) return null
        if (customerQuery.isError) return <div>There was a problem</div>

        const customer = customerQuery.data

        return (
          <>
            <InnerHeader {...{ customer }} />

            <Fade in={isEditing} unmountOnExit>
              <CustomerForm
                {...{
                  obj: customer,
                  setSidebarId,
                  saveObject: sidebarId === 'new' ? createCustomer : updateCustomer,
                  stopEditing,
                  onDismiss,
                }}
              />
            </Fade>

            <Fade in={!isEditing} unmountOnExit>
              <CustomerInfo {...{ customer, startEditing }} />
            </Fade>

            {customer && (
              <>
                <Divider />
                <Documents customer={customer as Customer} />

                <Divider />
                <CustomerBilling {...{ customer }} />
              </>
            )}
          </>
        )
      })()}
    </Sidebar>
  )
}

export function Sidebar({ isOpen, onClose, header, footer, children }) {
  return (
    <Drawer isOpen={isOpen} placement="left" onClose={onClose} size="md">
      <DrawerOverlay />

      <DrawerContent>
        {header && <DrawerHeader bg="gray.700">{header}</DrawerHeader>}
        <DrawerBody width="100%">{children}</DrawerBody>
        {footer && <DrawerFooter>{footer}</DrawerFooter>}
      </DrawerContent>
    </Drawer>
  )
}

function Header({ isNew }) {
  return (
    <Row alignItems="center">
      <Box color="white">{isNew ? 'New Customer' : 'Customer'}</Box>
    </Row>
  )
}
function Footer(props: any) {
  return null
}

function InnerHeader({ customer }: { customer: Customer }) {
  return (
    <Row justifyContent="flex-end" alignItems="center" pt={2} pb={4}>
      {customer?.created_at && (
        <Box>
          <TimeLabel label="Created" value={new Date(customer?.created_at).toLocaleDateString()} />
          {customer?.updated_at && (
            <>
              &nbsp;&nbsp;-&nbsp;&nbsp;
              <TimeLabel
                label="Updated"
                value={new Date(customer?.updated_at).toLocaleDateString()}
              />
            </>
          )}
        </Box>
      )}
    </Row>
  )
}

const customerMetaKeys = [
  {
    key: 'panel_count',
    label: 'Panel Count',
  },
]

function CustomerInfo({
  customer,
  startEditing,
}: {
  customer: Customer
  startEditing: () => void
}) {
  if (!customer) return null

  return (
    <Column>
      <Row mb={1} justifyContent="space-between" alignItems="center">
        <Box fontSize={16} fontWeight={500}>
          {customer.name}
        </Box>

        <Box fontSize={16} color="#888" fontWeight={500}>
          {/* Vendor */}

          <Button size="xs" width="80px" colorScheme="purple" onClick={startEditing}>
            Edit
          </Button>
        </Box>
      </Row>

      <Row justifyContent="space-between" mb={4}>
        <Column>
          <Box fontSize={16}>
            {customer.address && customer.address.split(',').slice(0, 1)}
            <br />
            {customer.address && customer.address.split(',').slice(1).join(', ')}
          </Box>
        </Column>

        <Column justifyContent="flex-end" alignItems="flex-end">
          <Box>{customer.email}</Box>
          <Box>{utils.prettyPhoneNumber(customer.phone)}</Box>
        </Column>
      </Row>

      <Row justifyContent="space-between" mb={4}>
        <DetailItem label="Stripe ID" value={customer.stripe_id} />
        {/* <DetailItem label="Plan" value={customer.billing_plan} /> */}
        {/* <DetailItem label="Started" value={new Date(customer.created_at).toLocaleDateString()} /> */}
        {customerMetaKeys.map(({ key, label }) => (
          <DetailItem key={key} label={label} value={customer.meta?.[key]} />
        ))}
      </Row>
    </Column>
  )
}

function Divider() {
  return <Box height="1px" my={4} borderBottom="1px solid #eee" />
}

const TimeLabel = ({ label, value }) => {
  return (
    <small>
      <Box as="span" color="#777">
        {label}
      </Box>{' '}
      {value}
    </small>
  )
}
