import React from 'react'
import { get } from 'lodash'
import { FormControl, FormErrorMessage, Checkbox } from '@chakra-ui/react'

const FormikCheckbox = ({ field, form, disabled, id, label, checkboxProps = {} }) => {
  let isTouched = true === get(form.touched, field.name)
  let errorMessage = get(form.errors, field.name)
  let hasError = isTouched && !!errorMessage

  return (
    <FormControl isInvalid={hasError}>
      <Checkbox
        size="sm"
        id={id}
        name={field.name}
        isChecked={!!field.value}
        isInvalid={hasError}
        isDisabled={disabled}
        onBlur={field.onBlur}
        onChange={(e) => form.setFieldValue(field.name, e.target.checked)}
        {...checkboxProps}
      >
        {label}
      </Checkbox>

      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  )
}

export default FormikCheckbox
