import React from 'react'
import { get } from 'lodash'
import { FormControl, FormLabel, FormErrorMessage } from '@chakra-ui/react'
import Select from 'src/ui/select'

const FormikSelect = ({
  field,
  form,
  id,
  label,
  options = [],
  disabled,
  placeholder,
  selectProps = {},
  onChange,
}) => {
  let isTouched = true === get(form.touched, field.name)
  let errorMessage = get(form.errors, field.name)
  let hasError = isTouched && !!errorMessage

  return (
    <FormControl isInvalid={hasError}>
      {label && (
        <FormLabel htmlFor={id || field.name} fontSize="sm">
          {label}
        </FormLabel>
      )}

      <Select
        id={id}
        name={field.name}
        value={field.value}
        options={options}
        placeholder={placeholder}
        hasError={hasError}
        isDisabled={disabled}
        onChange={(item) => {
          // allow custom onChange as well
          if (onChange) onChange(item)

          // https://github.com/jaredpalmer/formik/issues/640
          form.setFieldValue(field.name, item ? item.value : '')
        }}
        onBlur={field.onBlur}
        {...selectProps}
      />

      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  )
}

export default FormikSelect
