import React from 'react'
import { Box, Row, Heading, Spinner, Divider, Badge } from 'src/ui'
import CustomerDetails from 'src/customer/detail'
import CustomerDocuments from 'src/customer/documents'
import CustomerBilling from 'src/customer/billing'
import { useMe } from 'src/queries'

export function CustomerHome(_props) {
  const meQuery = useMe()

  if (meQuery.isLoading) return <Loading />
  if (meQuery.isError) return <Error />

  const customer = meQuery.data

  return (
    <Box p={4} bg="white" borderRadius={4} boxShadow="md" mb={6}>
      <Row pb={4} mb={6} borderBottom="1px solid" borderColor="gray.200">
        <Heading size="md" fontWeight={400}>
          My Account <Badge textTransform="unset">{customer?.id}</Badge>
        </Heading>
      </Row>

      <Row flexDirection={['column', null, null, 'row']}>
        <Box width={['100%', null, null, '50%']} mb={[6, null, null, 0]}>
          <CustomerDetails customer={customer} />
        </Box>

        <Box width={[0, null, null, '24px']} />

        <Box width={['100%', null, null, '50%']}>
          <CustomerDocuments customer={customer} />
          <Divider my={6} />
          <CustomerBilling customer={customer} />
        </Box>
      </Row>
    </Box>
  )
}

function Loading() {
  return (
    <Row width="100%" mt="20vh" justifyContent="center">
      <Spinner speed="0.65s" size="xl" />
    </Row>
  )
}
function Error() {
  return (
    <Row width="100%" mt="20vh" justifyContent="center">
      There was an error loading your profile.
    </Row>
  )
}
