import React, { useCallback, useState } from 'react'
import { useQueryClient, useQuery, useMutation } from 'react-query'
import * as api from 'src/api'
import { Box, Row, Column, Input, Heading, Textarea, useToast } from 'src/ui'

export default function ConfigPage() {
  const { configQuery, updateConfigValue } = useAdminConfigQueries()

  // console.log('!!! config-page - render', { configQuery })

  if (!configQuery.isSuccess) return null

  return (
    <Column>
      <Heading size="lg" borderBottom="1px solid #ccc" pb={4} mb={8}>
        Config
      </Heading>

      {Object.keys(configQuery.data ?? {}).map((key) => {
        return (
          <Row key={key} width="100%" mb={4} fontFamily="mono" fontSize="sm">
            <Box flex="0 0 300px">{key}</Box>
            <Box flex="1 1 auto" overflow="auto">
              {['string', 'number'].includes(typeof configQuery.data[key]) ? (
                <FormInput
                  _key={key}
                  initValue={configQuery.data[key]}
                  updateConfigValue={updateConfigValue}
                />
              ) : (
                <FormTextarea
                  _key={key}
                  initValue={JSON.stringify(configQuery.data[key], null, 2)}
                  updateConfigValue={updateConfigValue}
                />
              )}
            </Box>
          </Row>
        )
      })}
    </Column>
  )
}

///////////////////////////////////////////////////////////////////////////////////////////////////

function FormInput({ _key, initValue, updateConfigValue }) {
  const [value, setValue] = useState(() => initValue)
  const submitHandlers = useSubmitHandlers({ configKey: _key })
  const onBlur = useCallback(
    async (e) => {
      if (e.target.value === initValue) return
      updateConfigValue({ key: _key, value: e.target.value }, submitHandlers)
    },
    [_key, initValue, submitHandlers, updateConfigValue]
  )

  return (
    <Input
      size="sm"
      width="80%"
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={onBlur}
    />
  )
}

function FormTextarea({ _key, initValue, updateConfigValue }) {
  const [value, setValue] = useState(() => initValue)
  const submitHandlers = useSubmitHandlers({ configKey: _key })
  const onBlur = useCallback(
    async (e) => {
      if (JSON.stringify(JSON.parse(e.target.value)) === JSON.stringify(JSON.parse(initValue))) {
        return
      }

      let value

      try {
        value = JSON.parse(e.target.value)
      } catch (err) {
        console.log('failed to parse json', err)
      }

      if (!value) return

      updateConfigValue({ key: _key, value }, submitHandlers)
    },
    [_key, initValue, submitHandlers, updateConfigValue]
  )

  return (
    <Textarea
      fontSize="sm"
      height="300px"
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={onBlur}
    />
  )
}

///////////////////////////////////////////////////////////////////////////////////////////////////

function useAdminConfigQueries() {
  const queryClient = useQueryClient()

  const configQuery = useQuery(['admin-config'], async (_key) => {
    const [err, response] = await api.getAdminConfig()

    if (err) throw err

    return sortConfigByKeys(response.data.data)
  })

  const { mutate: updateConfigValue } = useMutation(
    async ({ key, value }: { key: string; value: string }) => {
      const [err, result] = await api.updateAdminConfig({ key, value })
      if (err) throw err

      queryClient.setQueryData('admin-config', sortConfigByKeys(result.data.data))
    }
  )

  return { configQuery, updateConfigValue }
}

function sortConfigByKeys(config) {
  return Object.keys(config)
    .sort((a, b) => (a < b ? -1 : 1))
    .reduce((acc, key) => ({ ...acc, [key]: config[key] }), {})
}

function useSubmitHandlers({ configKey }: { configKey: string }) {
  const toast = useToast()

  return {
    onSuccess: useCallback(
      async (_response, args) => {
        toast({
          title: 'Success',
          description: `Updated config key "${configKey}"`,
          status: 'success',
          duration: 4000,
          isClosable: false,
          position: 'top-right',
        })
      },
      [toast, configKey]
    ),

    onError: useCallback(
      async (...args) => {
        toast({
          title: 'Whoops',
          description: `Failed to save config key "${configKey}"`,
          status: 'error',
          duration: 4000,
          isClosable: false,
          position: 'top-right',
        })
      },
      [toast, configKey]
    ),
  }
}
