import React from 'react'
import { get } from 'lodash'
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  NumberInput,
  NumberInputField,
} from '@chakra-ui/react'

const FormikInput = ({
  field,
  form,
  disabled,
  isReadyOnly = false,
  _ref,
  id,
  label,
  placeholder,
  type = 'text',
  inputRef,
  ...rest
}) => {
  let isTouched = true === get(form.touched, field.name)
  let errorMessage = get(form.errors, field.name)
  let hasError = isTouched && !!errorMessage

  return (
    <FormControl isInvalid={hasError}>
      {label && (
        <FormLabel htmlFor={id || field.name} fontSize="sm">
          {label}
        </FormLabel>
      )}

      <NumberInput
        size="sm"
        min={0}
        value={field.value === 0 ? '0.00' : field.value}
        precision={2}
        isDisabled={disabled}
        isReadOnly={isReadyOnly}
        onChange={(valueAsString: string, valueAsNumber: number) =>
          form.setFieldValue(field.name, valueAsString)
        }
      >
        <NumberInputField
          ref={_ref}
          id={id || field.name}
          name={field.name}
          placeholder={placeholder}
          onBlur={field.onBlur}
        />
      </NumberInput>
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  )
}

export default FormikInput
