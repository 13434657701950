import React from 'react'
import { RouteComponentProps } from '@reach/router'
import DriverSchedules from 'src/manage/driver-schedules'
import UserList from 'src/manage/drivers/list'
import UserForm from 'src/manage/users/form'
import { DeleteDialog } from 'src/manage/dialogs'
import { useVehicles, useUsers, useUserMutations } from 'src/queries'
import { useMutationHandlers } from 'src/utils/queries'
import { useDisclosure } from 'src/ui'

const Users = (_props: RouteComponentProps) => {
  let [objectToEdit, setObjectToEdit] = React.useState<User>()
  let { isOpen, onOpen, onClose } = useDisclosure()
  let { deleteDialog, setDeleteDialog, resetDeleteDialog } = useDeleteDialog()

  let vehiclesQuery = useVehicles()
  let usersQuery = useUsers()
  let { createUser, updateUser, deleteUser } = useUserMutations()
  let { onSuccess, onError } = useMutationHandlers({
    invalidateQueries: ['users'],
    successMessage: 'User Deleted',
    errorMessage: 'Failed to delete user',
  })

  return (
    <>
      <UserList
        users={(usersQuery.data || []).filter((u) => u.role === 'driver')}
        isLoading={usersQuery.status === 'loading'}
        startCreate={() => {
          onOpen()
        }}
        startEdit={(user) => {
          setObjectToEdit(user)
          onOpen()
        }}
        startDelete={(obj) => setDeleteDialog((state) => ({ ...state, obj }))}
      />

      <UserForm
        isOpen={isOpen}
        obj={objectToEdit}
        vehicles={vehiclesQuery.data || []}
        saveObj={objectToEdit ? updateUser : createUser}
        onDismiss={() => {
          setObjectToEdit(null)
          onClose()
        }}
      />

      <DeleteDialog
        isOpen={!!deleteDialog.obj}
        isLoading={deleteDialog.fetching}
        title="Delete User"
        body={
          <>Are you sure you want to delete "{deleteDialog.obj ? deleteDialog.obj.name : ''}"?</>
        }
        onDismiss={resetDeleteDialog}
        onConfirm={async () => {
          setDeleteDialog((state) => ({ ...state, fetching: true }))

          deleteUser(
            { id: deleteDialog.obj.id },
            {
              onSuccess: () => {
                onSuccess()
                resetDeleteDialog()
              },
              onError,
            }
          )
        }}
      />

      <DriverSchedules />
    </>
  )
}

export default Users

///////////////////////////////////////////////////////////////////////////////////////////////////

export const useDeleteDialog = () => {
  let mountedRef = React.useRef(true)

  let dialogInitial = { fetching: false, obj: null }
  let [deleteDialog, setDeleteDialog] = React.useState(dialogInitial)

  React.useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  return {
    deleteDialog,
    setDeleteDialog,
    resetDeleteDialog: () => {
      if (!mountedRef.current) return
      setDeleteDialog(dialogInitial)
    },
  }
}
