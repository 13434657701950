import React, { useCallback, useMemo, useState } from 'react'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { Box, Row, Column, Button, FormLabel, FormikElements, Stack } from 'src/ui'
import { SubscriptionRow } from 'src/billing/shared/subscription-row'
import {
  useVendorMutations,
  useCustomerMutations,
  useDeliveryMutations,
  useCustomerBillingById,
} from 'src/queries'
import * as utils from 'src/utils'
import * as deliveryUtils from 'src/deliveries/utils'
import {
  FormValues,
  FormikBag,
  useFormData,
  useSubmitHandlers,
  addressLoadOptions,
  getVendorRate,
  useCustomerChange,
  handleVendorChange,
  handleCustomerUpdate,
  useBilling,
} from 'src/deliveries/form-helpers'
import { BoxProps, FormControl, Spinner } from '@chakra-ui/react'

const PAYMENT_METHODS = [
  {
    value: 'cash',
    label: 'Cash',
  },
  {
    value: 'check',
    label: 'Check',
  },
  {
    value: 'credit_card',
    label: 'Credit Card',
  },
  {
    value: 'stripe',
    label: 'Stripe',
  },
]

const FormSchema = Yup.object().shape({
  starts_at: Yup.string().required('Required'),
  ends_at: Yup.string().required('Required'),
  driver_id: Yup.string().required('Required').typeError('Required'),
  service_id: Yup.string().required('Required').typeError('Required'),
  service_variant_id: Yup.string().nullable(),
  subscription_id: Yup.string().nullable(),
  price: Yup.string().nullable(),
  payment_method: Yup.string().nullable(),
  invoice: Yup.string().nullable(),
  customer_phone: Yup.string().nullable(),
  customer_email: Yup.string().nullable(),
  pickup_address: Yup.string().nullable(),
  delivery_address: Yup.string().nullable(),
  instructions: Yup.string().nullable(),
  description: Yup.string().nullable(),
  notify_sms: Yup.boolean().nullable(),
  notify_email: Yup.boolean().nullable(),
})

interface TaskFormProps {
  delivery?: TaskWithService
  stopEditing: () => void
}

export default function TaskForm({ delivery, stopEditing }: TaskFormProps) {
  const { onSuccess, onError } = useSubmitHandlers({ stopEditing })
  const { createDelivery, updateDelivery } = useDeliveryMutations()
  const deliveryId = delivery?.id

  const onSubmit = useCallback(
    async (values: FormValues, _formikBag: FormikBag) => {
      const body = {
        ...values,
        id: deliveryId ?? undefined,
        vendor_phone: utils.e164PhoneNumber(values.vendor_phone),
        customer_phone: utils.e164PhoneNumber(values.customer_phone),
        price: Number(values.price),
      }

      if (deliveryId) {
        updateDelivery(body, { onSuccess, onError })
      } else {
        createDelivery(body, { onSuccess, onError })
      }
    },
    [deliveryId, updateDelivery, createDelivery, onError, onSuccess]
  )

  const { isLoading, ...formDataRest } = useFormData()

  if (!delivery) return null
  if (isLoading) return <Loading />

  return (
    <Formik
      initialValues={{
        ...delivery,
        notify_sms: delivery.id ? delivery.notify_sms || false : true,
        notify_email: delivery.notify_email || false,
        vendor_phone: utils.parseToNationalNumber(delivery.vendor_phone),
        customer_phone: utils.parseToNationalNumber(delivery.customer_phone),
      }}
      validationSchema={FormSchema}
      validateOnChange={false}
      onSubmit={onSubmit}
    >
      {(formikBag: FormikBag) => <TheForm {...{ formikBag, delivery, ...formDataRest }} />}
    </Formik>
  )
}

function TheForm({
  formikBag,
  delivery,
  services,
  drivers,
  vendors,
  customers,
  driverOptions,
  vendorNameOptions,
  customerNameOptions,
}: {
  formikBag: FormikBag
  delivery: TaskWithService
  services: TaskService[]
  drivers: User[]
  vendors: Vendor[]
  customers: Customer[]
  driverOptions: { value: string; label: string }[]
  vendorNameOptions: { value: string; label: string }[]
  customerNameOptions: { value: string; label: string }[]
}) {
  const { setFieldValue } = formikBag
  const [showDateChange, setShowDateChange] = useState(false)
  const { createVendor } = useVendorMutations()
  const { createCustomer, updateCustomer } = useCustomerMutations()

  const resetServiceVariantId = useCallback(
    () => setFieldValue('service_variant_id', null),
    [setFieldValue]
  )

  useCustomerChange({ delivery, formikBag, customers, createCustomer })

  const hasFrozenBilling = false
  const formikServiceId = formikBag.values.service_id
  const serviceOptions = services?.map((service) => ({ value: service.id, label: service.name }))
  const selectedService =
    formikServiceId !== delivery.service?.id
      ? services?.find(({ id }) => id === formikServiceId)
      : delivery.service

  return (
    <>
      <Form id="delivery-form">
        <Row justifyContent="center" mb={4}>
          {showDateChange ? (
            <DateChangeControls />
          ) : (
            <DateChangeHeader {...{ delivery, setShowDateChange }} />
          )}
        </Row>

        <Row justifyContent="space-around" mb={2}>
          <Box flex={1}>
            {hasFrozenBilling ? (
              <ReadOnlyFormValue
                label="Service"
                value={
                  serviceOptions?.find((opt) => opt.value === formikServiceId)?.label ?? 'Unknown'
                }
              />
            ) : (
              <Field
                name="service_id"
                id="service_id"
                label="Service"
                component={FormikElements.Select}
                selectProps={{
                  width: '100%',
                  maxWidth: 150,
                  isClearable: false,
                }}
                options={serviceOptions}
                onChange={resetServiceVariantId}
              />
            )}
          </Box>

          <Box px={2} />

          <Box flex={1} height="72px">
            <Field
              name="driver_id"
              id="driver_id"
              label="Technician"
              component={FormikElements.Select}
              selectProps={{
                width: '100%',
                maxWidth: 150,
                isClearable: false,
              }}
              options={driverOptions}
              onChange={(e) => {
                let nextDriver = drivers.find((d) => d.id === e.value)
                if (!nextDriver) return
                formikBag.setFieldValue('driver_name', nextDriver.name)
              }}
            />
          </Box>
        </Row>

        {!!selectedService && (
          <>
            {selectedService.uses_simple_billing ? (
              <SimpleBilling {...{ paymentMethods: selectedService.payment_methods }} />
            ) : (
              <Billing
                {...{
                  formikBag,
                  delivery,
                  vendors,
                  services,
                  resetServiceVariantId,
                }}
              />
            )}
          </>
        )}

        {selectedService?.task_attrs?.length > 0 && (
          <Stack isInline justify="center" align="center" spacing={4}>
            {selectedService?.task_attrs.map((attr) => (
              <Box key={attr.name}>
                <Field
                  label={attr.label}
                  name={attr.name}
                  id={attr.name}
                  component={FormikElements.Checkbox}
                  checkboxProps={{ colorScheme: 'purple' }}
                />
              </Box>
            ))}
          </Stack>
        )}

        <Box my={4} height="100%" borderBottom="1px solid #eee" />

        {!!selectedService?.uses_vendor_address && (
          <VendorDetail {...{ formikBag, vendors, vendorNameOptions, createVendor }} />
        )}

        <CustomerDetail
          {...{
            formikBag,
            customers,
            customerNameOptions,
            createCustomer,
            updateCustomer,
            resetServiceVariantId,
          }}
        />

        <InstructionsAndNotifications />

        <Box as="pre" fontSize="10px" mt={8}>
          {JSON.stringify({ delivery, formikBag }, null, 2)}
        </Box>

        {/* <ButtonGroup spacing={2} mt={12}width="100%" d="flex" justifyContent="flex-end">
              <Button width="80px" size="sm" variant="ghost" onClick={stopEditing}>
                Cancel
              </Button>

              <Button
                width="80px"
                size="sm"
                colorScheme="purple"
                isLoading={formikBag.isSubmitting}
                onClick={formikBag.submitForm}
              >
                Save
              </Button>
            </ButtonGroup> */}
      </Form>
    </>
  )
}

// DATE HEADER / FORM
///////////////////////////////////////////////////////////////////////////////////////////////////

function DateChangeHeader({ delivery, setShowDateChange }) {
  return (
    <Column alignItems="center" position="relative">
      <Row fontSize={14}>{utils.formatLocal(delivery.starts_at, 'ddd, MMMM D')}</Row>

      <Row fontSize={20}>
        {utils.formatLocal(delivery.starts_at, 'h:mm')}
        {' - '}
        {utils.formatLocal(delivery.ends_at, 'h:mm A')}
      </Row>

      <Box fontSize={12} position="absolute" right="-50px" bottom="2px">
        <Button size="xs" variant="link" onClick={() => setShowDateChange((value) => !value)}>
          change
        </Button>
      </Box>
    </Column>
  )
}

function DateChangeControls() {
  return (
    <>
      <Box flex={1}>
        <Field
          label="Starts At"
          name="starts_at"
          id="starts_at"
          component={FormikElements.DatePicker}
          datePickerProps={{ popperPlacement: 'bottom-start' }}
          inputProps={{ size: 'sm' }}
        />
      </Box>

      <Box px={1} />

      <Box flex={1}>
        <Field
          label="Ends At"
          name="ends_at"
          id="ends_at"
          component={FormikElements.DatePicker}
          datePickerProps={{ popperPlacement: 'bottom-end' }}
          inputProps={{ size: 'sm' }}
        />
      </Box>
    </>
  )
}

// DRIVER / VEHICLE / BILLING / INVOICE
///////////////////////////////////////////////////////////////////////////////////////////////////

interface BillingProps {
  formikBag: FormikBag
  delivery: Delivery
  vendors: Vendor[]
  services: TaskService[]
  // paymentMethods: { value: string; label: string }[]
  resetServiceVariantId: () => void
}

function SimpleBilling({ paymentMethods }: { paymentMethods: string[] }) {
  return (
    <Row mb={4}>
      <Box flex={1}>
        <Field
          name="payment_method"
          id="payment_method"
          label="Payment Method"
          component={FormikElements.Select}
          selectProps={{ width: '100%', maxWidth: 150, isClearable: true }}
          options={PAYMENT_METHODS.filter((method) => paymentMethods.includes(method.value))}
        />
      </Box>

      <Box px={2} />

      <Box flex={1}>
        <Field
          name="price"
          id="price"
          component={FormikElements.NumberInput}
          label={
            <Row justifyContent="space-between">
              <Box as="span" pr={2}>
                Price
              </Box>
            </Row>
          }
        />
      </Box>
    </Row>
  )
}

function Billing({ formikBag, delivery, vendors, services, resetServiceVariantId }: BillingProps) {
  const [showDebug, setShowDebug] = useState(false)

  const {
    billingQuery,
    variantOptions,
    candidateSubscription,
    serviceBreakdown,
    isSelectedVariantSubscription,
    usesCustomPrice,
  } = useBilling({ formikBag })

  const serviceId = formikBag.values.service_id
  const serviceVariantId = formikBag.values.service_variant_id
  const paymentMethod = formikBag.values.payment_method

  const usesSubscriptionBilling = deliveryUtils.isTaskSubscription(delivery)
  const subscriptionId = usesSubscriptionBilling
    ? delivery.service_variant_id?.replace('subscription#', '')
    : null
  const isBillingQueryEnabled = !!formikBag.values.customer_id && !!subscriptionId

  const billingQueryForSubscription = useCustomerBillingById({
    customerId: formikBag.values.customer_id,
    enabled: isBillingQueryEnabled,
  })

  const customerSubscriptions = billingQueryForSubscription.data?.subscriptions

  const subscription = useMemo(() => {
    if (!customerSubscriptions || customerSubscriptions.length === 0) return null

    return customerSubscriptions.find((s) => s.id === subscriptionId)
  }, [subscriptionId, customerSubscriptions])

  // const serviceOptions = services?.map((service) => ({ value: service.id, label: service.name }))
  const selectedService = services?.find((service) => service.id === serviceId)
  const hasFrozenBilling =
    formikBag.values.id && (usesSubscriptionBilling || formikBag.values.payment_method === 'stripe')

  if (billingQuery.isLoading || billingQueryForSubscription.isLoading) {
    return <Loading mt={4} size="md" />
  }

  return (
    <>
      <Row mb={4}>
        {/* <Box flex={1}>
          {hasFrozenBilling ? (
            <ReadOnlyFormValue
              label="Service"
              value={serviceOptions?.find((opt) => opt.value === serviceId)?.label ?? 'Unknown'}
            />
          ) : (
            <Field
              name="service_id"
              id="service_id"
              label="Service"
              component={FormikElements.Select}
              selectProps={{
                width: '100%',
                maxWidth: 150,
                isClearable: false,
              }}
              options={serviceOptions}
              onChange={resetServiceVariantId}
            />
          )}
        </Box> */}

        <Box flex={1}>
          {hasFrozenBilling ? (
            <ReadOnlyFormValue
              label="Service Billing"
              value={
                variantOptions?.find((opt) => opt.value === serviceVariantId)?.label ??
                (usesSubscriptionBilling ? 'Subscription' : 'Unknown')
              }
            />
          ) : (
            <Field
              name="service_variant_id"
              id="service_variant_id"
              label="Service Billing"
              component={FormikElements.RadioGroup}
              options={variantOptions}
            />
          )}

          {!serviceId ? (
            <Box mt={4} fontStyle="italic" color="gray.600">
              Please select a service
            </Box>
          ) : !formikBag.values.customer_id ? (
            <Box mt={4} fontStyle="italic" color="gray.600">
              Please select a customer
            </Box>
          ) : billingQuery?.data?.breakdown?.error ? (
            <Box mt={4} fontStyle="italic" color="gray.600">
              {billingQuery?.data?.breakdown?.error}
            </Box>
          ) : null}
        </Box>
      </Row>

      {serviceVariantId && !isSelectedVariantSubscription && (
        <Row mb={4}>
          <Box flex={1}>
            {hasFrozenBilling ? (
              <ReadOnlyFormValue
                label="Payment Method"
                value={
                  PAYMENT_METHODS.find((opt) => opt.value === paymentMethod)?.label ?? 'Unknown'
                }
              />
            ) : (
              <Field
                name="payment_method"
                id="payment_method"
                label="Payment Method"
                component={FormikElements.Select}
                selectProps={{
                  width: '100%',
                  maxWidth: 150,
                  isClearable: false,
                }}
                options={PAYMENT_METHODS}
              />
            )}
          </Box>

          <Box px={2} />

          <Box flex={1}>
            {hasFrozenBilling ? (
              <ReadOnlyFormValue label="Price" value={formikBag.values.price} />
            ) : (
              <Field
                name="price"
                id="price"
                component={FormikElements.NumberInput}
                label={
                  <Row justifyContent="space-between">
                    <Box as="span" pr={2}>
                      Price
                    </Box>
                    {getVendorRate({ id: formikBag.values.vendor_id, vendors })}
                    {serviceBreakdown && !serviceBreakdown?.error && (
                      <Box>{utils.formatMoney(serviceBreakdown?.per_unit_price)}/panel</Box>
                    )}
                  </Row>
                }
                isReadyOnly={!usesCustomPrice}
              />
            )}
          </Box>
        </Row>
      )}

      {hasFrozenBilling ? (
        <>
          {usesSubscriptionBilling && subscription && (
            <SubscriptionRow title="Using Subscription" subscription={subscription} />
          )}
        </>
      ) : (
        <>
          {!!candidateSubscription && isSelectedVariantSubscription && (
            <SubscriptionRow title="Use Subscription" subscription={candidateSubscription} />
          )}
        </>
      )}

      <button type="button" onClick={() => setShowDebug((s) => !s)}>
        {showDebug ? 'hide' : 'show'}
      </button>
      {showDebug && (
        <Box as="pre" fontSize={11} boxShadow="md" outline="1px solid #cccc" p={2} mb={4}>
          {JSON.stringify(
            {
              bq_data: billingQuery.data,
              bqfs_data: billingQueryForSubscription.data,
              form_values: formikBag.values,
              selectedService,
            },
            null,
            2
          )}
        </Box>
      )}
    </>
  )
}

// VENDOR DETAIL / CUSTOMER DETAIL
///////////////////////////////////////////////////////////////////////////////////////////////////

function VendorDetail({ formikBag, vendors, vendorNameOptions, createVendor }) {
  return (
    <>
      <Row mb={2} justifyContent="space-between" alignItems="center">
        <Box fontSize={16} color="#888" fontWeight={500}>
          Vendor
        </Box>
      </Row>

      <Column>
        <Row>
          <Box flex={1} height="72px">
            <Field
              label="Vendor"
              name="vendor_name"
              id="vendor_name"
              component={FormikElements.SelectCreatable}
              selectProps={{
                width: '100%',
                isClearable: false,
              }}
              options={vendorNameOptions}
              onChange={(selected) =>
                handleVendorChange({
                  selected,
                  vendors,
                  formikBag,
                  createVendor,
                })
              }
            />
          </Box>

          <Box px={2} />

          <Box flex={1} height="72px">
            <Field
              label="Contact"
              name="vendor_contact"
              id="vendor_contact"
              component={FormikElements.Input}
              size="sm"
            />
          </Box>
        </Row>

        <Row>
          <Box flex={1} height="72px">
            <Field
              label="Street Address"
              name="pickup_address"
              id="pickup_address"
              component={FormikElements.SelectCreatableAsync}
              selectProps={{
                width: '100%',
                loadOptions: addressLoadOptions,
              }}
            />
          </Box>

          <Box px={2} />

          <Box flex={1} height="72px">
            <Field
              label="Phone"
              name="vendor_phone"
              id="vendor_phone"
              component={FormikElements.PhoneNumber}
              size="sm"
              autoComplete="off"
            />
          </Box>
        </Row>
      </Column>

      <Box mb={2}>
        <Field
          label={
            <Row justifyContent="space-between">
              <Box as="span" pr={2}>
                Invoice # / SBO
              </Box>
            </Row>
          }
          name="invoice"
          id="invoice"
          component={FormikElements.Input}
          size="sm"
        />
      </Box>

      <Box mb={2}>
        <Field
          label="Description"
          name="description"
          id="description"
          component={FormikElements.Textarea}
          textareaProps={{
            size: 'sm',
            css: {
              minHeight: 58,
            },
          }}
        />
      </Box>

      <Box my={3} height="100%" borderBottom="1px solid transparent" />
    </>
  )
}

function CustomerDetail({ formikBag, customerNameOptions, updateCustomer, resetServiceVariantId }) {
  return (
    <>
      <Row mb={1} justifyContent="space-between" alignItems="center">
        <Box fontSize={16} color="#888" fontWeight={500}>
          Customer
        </Box>
      </Row>

      <Column>
        <Row>
          <Box flex={1} height="72px">
            <Field
              label="Name"
              name="customer_name"
              id="customer_name"
              component={FormikElements.SelectCreatable}
              selectProps={{
                width: '100%',
                isClearable: false,
              }}
              options={customerNameOptions}
              onChange={resetServiceVariantId}
            />
          </Box>

          <Box px={2} />

          <Box flex={1} height="72px">
            <Field
              label="Phone"
              name="customer_phone"
              id="customer_phone"
              component={FormikElements.PhoneNumber}
              size="sm"
              autoComplete="off"
              onBlur={() => {
                handleCustomerUpdate({
                  apiKey: 'phone',
                  formKey: 'customer_phone',
                  formikBag,
                  updateCustomer,
                })
              }}
            />
          </Box>
        </Row>

        <Row flexDirection={['column', null, 'row']}>
          <Box flex={1} height="72px" mb={[2, null, 0]}>
            <Field
              label="Street Address"
              name="delivery_address"
              id="delivery_address"
              component={FormikElements.SelectCreatableAsync}
              selectProps={{
                width: '100%',
                loadOptions: addressLoadOptions,
              }}
              // onBlur={() => {
              //   handleCustomerUpdate({
              //     apiKey: 'address',
              //     formKey: 'delivery_address',
              //     formikBag,
              //     updateCustomer,
              //   })
              // }}
            />
          </Box>

          <Box px={[0, null, 2]} />

          <Box flex={1} height="72px" mb={[2, null, 0]}>
            <Field
              label="Email"
              name="customer_email"
              id="customer_email"
              component={FormikElements.Input}
              size="sm"
              autoComplete="off"
              onBlur={() => {
                handleCustomerUpdate({
                  apiKey: 'email',
                  formKey: 'customer_email',
                  formikBag,
                  updateCustomer,
                })
              }}
            />
          </Box>
        </Row>
      </Column>
    </>
  )
}

// INSTRUCTIONS / NOTIFICATIONS
///////////////////////////////////////////////////////////////////////////////////////////////////

function InstructionsAndNotifications() {
  return (
    <Row mb={2}>
      <Box flex={1} height="72px">
        <Field
          label="Instructions"
          name="instructions"
          id="instructions"
          component={FormikElements.Textarea}
          textareaProps={{
            size: 'sm',
            css: {
              minHeight: 58,
            },
          }}
        />
      </Box>

      <Box width="130px" height="72px" ml={4}>
        <Row height="22px">
          <FormLabel fontSize="sm">Notifications</FormLabel>
        </Row>

        <Row width="100%" justifyContent="space-between" alignItems="center" mb={2}>
          <Field label="SMS" name="notify_sms" component={FormikElements.Switch} />
        </Row>

        <Row width="100%" justifyContent="space-between" alignItems="center">
          <Field label="Email" name="notify_email" component={FormikElements.Switch} disabled />
        </Row>
      </Box>
    </Row>
  )
}

///////////////////////////////////////////////////////////////////////////////////////////////////

function ReadOnlyFormValue({ label = '', value = '' }: { label: string; value: string | number }) {
  return (
    <FormControl>
      <FormLabel fontSize="sm">{label}</FormLabel>
      {value}
    </FormControl>
  )
}

function Loading({ mt = '10vh', size = 'xl' }: { mt?: BoxProps['mt']; size?: string }) {
  return (
    <Row width="100%" mt={mt} justifyContent="center">
      <Spinner speed="0.65s" size={size} />
    </Row>
  )
}

// function Error() {
//   return (
//     <Row width="100%" mt="20vh" justifyContent="center">
//       There was an error loading billing information.
//     </Row>
//   )
// }
