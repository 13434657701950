import React, { useState } from 'react'
import { Box, Row, Button, Heading, Stack, Spacer } from 'src/ui'
import { CustomerDetailForm } from './form'
import { DetailItem } from './detail-item'
import * as utils from 'src/utils'

export default function CustomerDetails({ customer }: { customer: Customer }) {
  const [isEditing, setIsEditing] = useState(false)

  if (isEditing) {
    return <CustomerDetailForm customer={customer} stopEditing={() => setIsEditing(false)} />
  }

  return (
    <Stack maxW={400}>
      <Row>
        <Heading>{customer.name}</Heading>
      </Row>
      <Row fontSize="lg" color="gray.600">
        {customer.email}
      </Row>

      <Spacer my={4} />

      <Row pb={2} justifyContent="space-between" borderBottom="1px solid" borderColor="gray.200">
        <Box>
          <strong>Details</strong>
        </Box>
        <Box>
          <Button size="sm" variant="link" colorScheme="purple" onClick={() => setIsEditing(true)}>
            Edit
          </Button>
        </Box>
      </Row>

      <DetailItem label="Phone Number" value={utils.prettyPhoneNumber(customer.phone)} />
      <DetailItem label="Address" value={customer.address} />
    </Stack>
  )
}
