import React from 'react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { Location, Link, navigate } from '@reach/router'
import {
  Box,
  Row,
  Button,
  NavLink,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from 'src/ui'
import { GiHamburgerMenu } from 'react-icons/gi'
import useAuth from 'src/store/auth'

interface HeaderProps {
  auth: AuthState
  sidebarControl: IDisclosure
}

const Header = ({ auth, sidebarControl }: HeaderProps) => {
  const { user } = useAuth()

  return (
    // @ts-ignore
    <Location>
      {({ location: { pathname } }) => (
        <Box
          position="fixed"
          width="100vw"
          height="50px"
          px={4}
          bg="white"
          boxShadow="0 0 0 1px rgba(16, 22, 26, 0.1), 0 0 0 rgba(16, 22, 26, 0),
    0 1px 1px rgba(16, 22, 26, 0.2)"
          zIndex={10}
        >
          <Row height="50px" alignItems="center">
            <Box
              width="150px"
              display={['none', 'none', 'flex']}
              justifyContent="center"
              textTransform="uppercase"
              letterSpacing={2}
            >
              Falcon
            </Box>

            <Box display={['block', 'block', 'none']} px={3} fontWeight={600}>
              <IconButton
                icon={<Icon as={GiHamburgerMenu} boxSize="1.75rem" />}
                size="sm"
                variant="ghost"
                aria-label="Toggle Navigation"
                // fontSize="lg"
                color="black"
                _hover={{
                  backgroundColor: 'gray.100',
                  color: 'gray.800',
                }}
                onClick={sidebarControl.onOpen}
              />
            </Box>

            <Box mx={3} borderLeft="1px solid rgba(16, 22, 26, 0.15)" height="20px" />

            <Row display={['none', 'none', 'flex']} flex={1} px={3}>
              {user.entity === 'User' && <EmployeeNav user={auth.user} pathname={pathname} />}
              {user.entity === 'Customer' && <CustomerNav user={auth.user} pathname={pathname} />}
            </Row>

            <Row flex={1} />

            <Row justifyContent="center">
              <Menu closeOnSelect={false} placement="bottom-end">
                <MenuButton
                  as={Button}
                  {...{
                    variant: 'ghost',
                    size: 'sm',
                    rightIcon: <ChevronDownIcon />,
                  }}
                >
                  {auth.user.name}
                </MenuButton>

                <MenuList width="200px" minWidth="200px" maxHeight="300px" overflow="auto">
                  <MenuItem onClick={() => navigate('/logout')}>
                    <Link to="/logout">Logout</Link>
                  </MenuItem>
                </MenuList>
              </Menu>
            </Row>
          </Row>
        </Box>
      )}
    </Location>
  )
}

function CustomerNav({ user, pathname }) {
  return null
}

function EmployeeNav({ user, pathname }) {
  return (
    <>
      <Box px={1}>
        <NavLink to="/schedule" active={pathname.indexOf('/schedule') === 0}>
          Schedule
        </NavLink>
      </Box>

      {user.role === 'driver' && (
        <Box px={1}>
          <NavLink to="/checklist" active={pathname.indexOf('/checklist') === 0}>
            Checklist
          </NavLink>
        </Box>
      )}

      {user.role === 'admin' && (
        <Box px={1}>
          <NavLink to="/deliveries" active={pathname.indexOf('/deliveries') === 0}>
            Tasks
          </NavLink>
        </Box>
      )}

      {user.role === 'admin' && (
        <Box px={1}>
          <NavLink to="/reports" active={pathname.indexOf('/reports') === 0}>
            Reports
          </NavLink>
        </Box>
      )}

      {user.role === 'admin' && (
        <Box px={1}>
          <NavLink to="/manage" active={pathname.indexOf('/manage') === 0}>
            Manage
          </NavLink>
        </Box>
      )}
    </>
  )
}

export default Header
