import React from 'react'
import { useLocation, RouteComponentProps } from '@reach/router'
import Vendors from 'src/manage/vendors'
import Customers from 'src/manage/customers'
import Vehicles from 'src/manage/vehicles'
import Drivers from 'src/manage/drivers'
import Admins from 'src/manage/admins'
import Checklists from 'src/manage/checklists'
import Documents from 'src/manage/documents'
import { FlexRouter, Redirect } from 'src/routing'
import { Box, Row, ButtonLink, Stack } from 'src/ui'

const ManageRoot = () => {
  let { pathname } = useLocation()

  return (
    <Row flex={1} my={8}>
      <NavLinks path={pathname} />

      <Box mx={4} />

      <Box flex={1}>
        <FlexRouter basepath="/manage">
          <Vendors path="/vendors" />
          <Customers path="/customers" />
          <Vehicles path="/vehicles" />
          <Drivers path="/drivers" />
          <Admins path="/admins" />
          <Checklists path="/checklists" />
          <Documents path="/documents" />
          <RootRedirect default />
        </FlexRouter>
      </Box>
    </Row>
  )
}

export default ManageRoot

const RootRedirect = (_props: RouteComponentProps) => (
  // @ts-ignore
  <Redirect to="/manage/vendors" noThrow />
)

const NavLinks = ({ path }) => {
  return (
    <Stack
      mt="40px"
      flex="0 0 160px"
      css={{
        a: {
          justifyContent: 'flex-start',
        },
      }}
    >
      {[
        { label: 'Vendors', to: '/manage/vendors' },
        { label: 'Customers', to: '/manage/customers' },
        { label: 'Vehicles', to: '/manage/vehicles' },
        { label: 'Drivers', to: '/manage/drivers' },
        { label: 'Admins', to: '/manage/admins' },
        { label: 'Checklists', to: '/manage/checklists' },
        { label: 'Documents', to: '/manage/documents' },
      ].map(({ label, to }) => (
        <ButtonLink
          key={label}
          to={to}
          variant={path === to ? undefined : 'ghost'}
          backgroundColor={path === to ? 'gray.300' : undefined}
          color={path === to ? undefined : 'gray.600'}
        >
          {label}
        </ButtonLink>
      ))}
    </Stack>
  )
}
