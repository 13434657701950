import React, { useState, useEffect } from 'react'
import { get, groupBy } from 'lodash'
import { Box, Row, Table, useTable } from 'src/ui'
import { caseInsensitiveSort } from 'src/ui/table/helpers'
import { calcDeliveryStats } from 'src/deliveries/utils'
import * as utils from 'src/utils'

interface VendorListProps {
  isLoading: boolean
  deliveries: Delivery[]
  vendors: Vendor[]
  selectedVendors: string[]
}
export default function VendorList({
  isLoading,
  deliveries,
  vendors,
  selectedVendors,
}: VendorListProps) {
  const columns = useColumns()
  const summaries = useVendorSummary({ deliveries, vendors })

  return (
    <>
      {/* <Heading size="md" mb={4} color="gray.600">
        By Vendor
      </Heading> */}

      <SummaryTable
        columns={columns}
        // isLoading={isLoading || !summaries}
        isLoading={isLoading}
        data={summaries || []}
        maxHeight="500px"
        selectedVendors={selectedVendors}
      />
    </>
  )
}

function useVendorSummary({ deliveries, vendors }) {
  const [summaries, setSummaries] = useState(null)

  useEffect(() => {
    if (!deliveries || deliveries.length === 0) return
    if (!vendors || vendors.length === 0) return

    const grouped = groupBy(deliveries, 'vendor_id')

    const summaries = Object.keys(grouped).map((vendorId) => {
      const deliveries = grouped[vendorId]

      return {
        deliveries,
        vendor: vendors.find((v) => v.id === vendorId),
        ...calcDeliveryStats(deliveries),
      }
    })

    setSummaries(summaries)
  }, [deliveries, vendors])

  return summaries
}

///////////////////////////////////////////////////////////////////////////////////////////////////

const nullAwareAccessor = (key) => (row) => get(row, key) || undefined
const filterVendors = (rows: any[], _id: string, filterValue: string[]) =>
  rows.filter((row) => filterValue.includes(row.original.vendor?.id))

function SummaryTable({ columns, isLoading, data, selectedVendors, maxHeight }) {
  let {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    // rows,
    page,
    pagination,
    // setFilter,
    setHiddenColumns,
    totalCount,
  } = useTable({
    columns,
    data,
    tableOptions: {
      initialState: {
        sortBy: [{ id: 'delivery_count', desc: true }],
      },
      autoResetFilters: false,
    },
  })

  // useEffect(() => {
  //   setFilter('vendor_name', selectedVendors.length > 0 ? selectedVendors : undefined)
  // }, [selectedVendors, setFilter])

  return (
    <Box>
      <Table.ScrollableWrapper maxHeight={maxHeight}>
        <Table {...getTableProps()}>
          <Table.Header headerGroups={headerGroups} />
          <Table.Body
            isLoading={isLoading}
            rows={page}
            getTableBodyProps={getTableBodyProps}
            prepareRow={prepareRow}
            // onRowClick={(obj) => startEdit(obj)}
          />
        </Table>
      </Table.ScrollableWrapper>

      {!isLoading && (
        <Table.Pagination
          totalCount={totalCount}
          allowSelectPageSize
          {...pagination}
          showColumnSelection
          columns={columns}
          hiddenColumns={[]}
          setHiddenColumns={setHiddenColumns}
        />
      )}
    </Box>
  )
}

function useColumns() {
  return React.useMemo(
    () => [
      {
        id: 'vendor_name',
        Header: 'Vendor',
        accessor: nullAwareAccessor('vendor.name'),
        filter: filterVendors,
        sortType: caseInsensitiveSort,
      },
      {
        id: 'vendor_phone',
        Header: 'Vendor Phone',
        accessor: (row) => (row.vendor?.phone ? utils.prettyPhoneNumber(row.vendor?.phone) : '-'),
      },
      // {
      //   id: 'vendor_contact',
      //   Header: 'Vendor Contact',
      //   accessor: (row) => row.vendor?.contact ?? '-',
      // },
      {
        id: 'delivery_count',
        Header: `Total Tasks`,
        accessor: 'deliveryCount',
      },
      {
        id: 'corporate_count',
        Header: 'Corporate',
        accessor: 'corporateCount',
        Cell: ({ row: { original: data } }) => (
          <NumberWithPercentage value={data.corporateCount} percentage={data.corporatePercent} />
        ),
      },
      {
        id: 'priced_count',
        Header: 'Priced',
        accessor: 'pricedCount',
        Cell: ({ row: { original: data } }) => (
          <NumberWithPercentage value={data.pricedCount} percentage={data.pricedPercent} />
        ),
      },
      {
        id: 'avg_price',
        Header: 'Avg Price',
        accessor: 'averagePrice',
        Cell: ({ row: { original: data } }) =>
          data.averagePrice === 0 ? '-' : utils.formatMoney(data.averagePrice),
      },
    ],
    []
  )
}

function NumberWithPercentage({ value, percentage }) {
  return (
    <Row alignItems="center">
      <Box minWidth="24px" mr={2}>
        {value}
      </Box>

      {value > 0 && (
        <Box as="span" color="gray.600">
          ({percentage}%)
        </Box>
      )}
    </Row>
  )
}
