import { useRef } from 'react'
import { Link } from '@reach/router'
import { useAuthState } from 'src/store/auth'
import {
  Box,
  Row,
  Column,
  Button,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
} from 'src/ui'
import Header from 'src/app/header'

const Layout = ({ children }) => {
  const auth = useAuthState()
  const sidebarControl = useDisclosure()

  return (
    <Column height="100vh">
      {auth.token && <Header auth={auth} sidebarControl={sidebarControl} />}
      {auth.token && <SidebarDrawer auth={auth} sidebarControl={sidebarControl} />}

      <Column
        flex={1}
        width={['100%', '91.66%']}
        maxWidth="2000px"
        minHeight="calc(100vh - 50px)"
        mt="50px"
        mx="auto"
        pt={3}
      >
        {/* <Row width="100%" height="20px" bg={['red', 'blue', 'green']} /> */}
        {children}
      </Column>
    </Column>
  )
}

export default Layout

///////////////////////////////////////////////////////////////////////////////

interface SidebarProps {
  role: string
}

const Sidebar = ({ role }: SidebarProps) => {
  // cannot use @reach/router's useLocation hook b/c this is outside routing context
  let { pathname } = window.location
  const isAdmin = role === 'admin'
  const isDriver = role === 'driver'
  const isAdminOrDriver = isAdmin || isDriver
  // const _isCustomer = !role

  return (
    <Box>
      <Row
        alignItems="stretch"
        position="fixed"
        top="3rem"
        left="0"
        width="100%"
        height="calc(100vh - 3rem)"
      >
        <Column width="100%" height="100%" px={4} py={8}>
          {isAdminOrDriver && (
            <NavItem to="/schedule" active={pathname.indexOf('/schedule') === 0} label="Schedule" />
          )}
          {isAdmin && (
            <NavItem to="/deliveries" active={pathname === '/deliveries'} label="Deliveries" />
          )}
          {isDriver && (
            <NavItem
              to="/checklist"
              active={pathname.indexOf('/checklist') === 0}
              label="Safety Checklist"
            />
          )}
          {isAdmin && <NavItem to="/reports" active={pathname === '/reports'} label="Reports" />}
          {isAdmin && (
            <NavItem to="/manage" active={pathname.indexOf('/manage') === 0} label="Manage" />
          )}
        </Column>
      </Row>
    </Box>
  )
}

const NavItem = ({ to = '', label = null, active = false }) => (
  <Box
    mb={1}
    borderRadius={3}
    cursor="pointer"
    fontSize="md"
    fontWeight={600}
    bg={active ? 'gray.200' : undefined}
    _hover={{
      backgroundColor: 'gray.200',
    }}
  >
    <Link
      to={to}
      css={{
        display: 'block',
        width: '100%',
        padding: '0.5rem',
        textDecoration: 'none',
        '&:hover': { textDecoration: 'none' },
      }}
    >
      {label}
    </Link>
  </Box>
)

const SidebarDrawer = ({ auth, sidebarControl }) => {
  const { isOpen, onClose } = sidebarControl
  const btnRef = useRef()

  return (
    <Drawer isOpen={isOpen} placement="left" onClose={onClose} finalFocusRef={btnRef}>
      <DrawerOverlay />

      <DrawerContent>
        <DrawerCloseButton top="13px" color="white" />
        <DrawerHeader bg="gray.700" color="white">
          Falcon
        </DrawerHeader>

        <DrawerBody width="100%">
          <Sidebar role={auth.user.role} />
        </DrawerBody>

        {false && (
          <DrawerFooter>
            <Button colorScheme="cyan">Action</Button>
          </DrawerFooter>
        )}
      </DrawerContent>
    </Drawer>
  )
}
